import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OutputProcess } from '../../models/views-models/outputProcess.model';
import { getProject } from '../../utils/projectUtils';
import { Response } from '../../models/response.model';
import { Observable, map } from 'rxjs';
import { Tag } from '../../models/views-models/tag.model';
import { CurrentProjectService } from './current-project.service';

const API = environment.serverUrl;

@Injectable({
    providedIn: 'root',
})
export class ProcessOutputService {
    constructor(private httpClient: HttpClient,  private currentProjectService: CurrentProjectService) {}

    getAllOutputProcesses(): Observable<OutputProcess[]> {
        return this.httpClient.get<Response>(`${API}outputs`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const outputs: OutputProcess[] = response.data.sort((a, b) => a.name.localeCompare(b.name));
                return outputs;
            })
        );
    }

    newOutputProcess(outputProcess: OutputProcess) {
        const project = this.currentProjectService.getCurrentProject();
        outputProcess.projectId = project.id;
        return this.httpClient.post<Response>(`${API}outputs`, outputProcess, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const output: OutputProcess = response.data;
                return output;
            })
        );
    }

    editOutputProcess(output: OutputProcess) {
        return this.httpClient
            .patch<Response>(`${API}outputs/edit/${output.id}`, output, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const output: OutputProcess = response.data;
                    return output;
                })
            );
    }

    deleteOutputProcess(id: string): Observable<Tag[]> {
        return this.httpClient.delete<Response>(`${API}outputs/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const tags: Tag[] = response.data;
                return tags;
            })
        );
    }

    updateOutputStatus(output: OutputProcess): Observable<OutputProcess> {
        return this.httpClient
            .patch<Response>(`${API}outputs/save-status/${output.id}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const output: OutputProcess = response.data;
                    return output;
                })
            );
    }

    private getHeaders(): HttpHeaders {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const token = currentUser?.token;
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
        });
        return headers;
    }
}
