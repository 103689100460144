import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { OpcServer } from 'src/app/shared/models/views-models/opcServer.model';
import { Process } from 'src/app/shared/models/views-models/process.model';
import { ProcessType } from 'src/app/shared/models/views-models/processType.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { Unit } from 'src/app/shared/models/views-models/unit.model';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { OpcServerService } from 'src/app/shared/service/views-services/opcServer.service';
import { ProcessInputService } from 'src/app/shared/service/views-services/process.service';
import { ProcessTypeService } from 'src/app/shared/service/views-services/processType.service';
import { TagService } from 'src/app/shared/service/views-services/tag.service';
import { UnitService } from 'src/app/shared/service/views-services/unit.service';
import { getUserData } from 'src/app/shared/utils/userRole';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { PopUpMessagesComponent } from '../../pop-up-messages/pop-up-messages.component';

@Component({
    templateUrl: './process-input.component.html',
    styleUrls: ['./process-input.component.scss'],
})
export class ProcessInputComponent implements OnInit {
    inputInfo =
        'Ao criar uma entrada analógica, são calculados seu filtro e gradiente, identificados com o prefixo AVG e GRD, respectivamente, nas próximas telas';

    processForm: UntypedFormGroup;
    process: Process = new Process();
    isEditing: boolean;
    dialogRefMsg: any;
    AVGTag: Tag;
    SPmax: number;
    SPmin: number;

    successMessage = MessagesEnum.SuccessMessage;
    deleteMessage = MessagesEnum.DeleteMessage;
    inputDeleteErrorMessage = MessagesEnum.inputDeleteErrorMessage;
    failureMessage = MessagesEnum.FailureNameMessage;
    restrictNameMessage = MessagesEnum.restrictNameMessage;
    invalidLimits = MessagesEnum.invalidLimits;
    invalidFormMessage = MessagesEnum.invalidFormMessage;
    validationError = MessagesEnum.validationError;
    minMaxValidationErrorMessage = MessagesEnum.minMaxValidationErrorMessage;
    successIcon: string = 'assets/images/status/successMessage.svg';
    errorIcon: string = 'assets/images/status/errorMessage.svg';
    confirmationPopUpType = 'titlePopUpConfirmationMessage';
    informationPopUpType = 'titlePopUpInformationMessage';

    user: any;
    currentProcess: Process;

    previousTypeIsDigital = false;
    isLimit = false;
    processTypes: ProcessType[];
    units: Unit[];
    dependencies: Tag[];
    opcServers: OpcServer[];

    isLimitConst = true;
    inputType = 'number';
    currentMaxLimit: any;
    currentMinLimit: any;
    limitOptions = [];
    currentProcessType: any;
    showRadioButton = false;
    currentOpcServer: OpcServer;
    isLoading: boolean = false;
    loaderMessage: string = '';

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    constructor(
        private unitService: UnitService,
        private processTypeService: ProcessTypeService,
        private processService: ProcessInputService,
        private opcServerService: OpcServerService,
        private tagService: TagService,
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        public currentProjectService: CurrentProjectService,
        private dialogRef: MatDialogRef<ProcessInputComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    async ngOnInit() {
        this.user = getUserData();
        this.isEditing = false;
        if (this.data != null) {
            this.currentProcess = Object.assign({}, this.data);
            this.isEditing = true;
        }
        await this.initForm();
    }

    async initForm() {
        this.processForm = this.formBuilder.group(
            {
                id: [null],
                name: [null, Validators.required],
                description: [null, Validators.required],
                opc_address: [null, Validators.required],
                opc_server: [null, Validators.required],
                process_type: [null, Validators.required],
                min: [{ value: '', disabled: false }, [Validators.required]],
                max: [{ value: '', disabled: false }, [Validators.required]],
                unit: [{ value: '', disabled: false }, [Validators.required]],
            },
            { validators: this.minMaxValidator }
        );
        if (!this.user.permissions.canUpdate || this.currentProjectService.isRunningMode()) {
            this.processForm.disable();
        }
        await this.loadOptions();
        if (this.isEditing) {
            this.fillEditForm();
            await this.getDependencies();
        }
    }

    async loadOptions(): Promise<void> {
        try {
            const units = await this.unitService.getAllUnits().toPromise();
            this.units = units.sort((a, b) => a.description.localeCompare(b.description));

            const processTypes = await this.processTypeService.getAllProcessTypes().toPromise();
            this.processTypes = processTypes
                .filter(
                    (process) =>
                        ![
                            VariableTypeEnum.VIRTUAL_SETPOINT.valueOf(),
                            VariableTypeEnum.SYSTEM.valueOf(),
                            VariableTypeEnum.SETPOINT_STATUS.valueOf(),
                            VariableTypeEnum.OUTPUT.valueOf(),
                        ].includes(process.name)
                )
                .sort((a, b) => a.description.localeCompare(b.description));

            const limit = await this.processService.getAllProcessesByType(VariableTypeEnum.LIMIT).toPromise();
            this.limitOptions = limit.sort((a, b) => a.tag.name.localeCompare(b.tag.name));

            const opcServers = await this.opcServerService.getAllOpcServers().toPromise();
            this.opcServers = opcServers.map((opcServer, i) => ({
                ...opcServer,
            }));
        } catch (error) {
            console.error(error);
        }
    }

    async fillEditForm() {
        const { process_type, tag, unit, opc_address, opcServerId, tagMin, tagMax } = this.data;
        this.isLimitConst = !Boolean(tagMax);
        await this.inputTypeChange();
        this.showRadioButton = this.isToDisplayRadioButton(process_type.name);
        this.currentOpcServer = this.data;

        const opcServer = this.opcServers.find(({ id }) => id === opcServerId);
        const min = this.isLimitConst ? tag.min : tagMin.name;
        const max = this.isLimitConst ? tag.max : tagMax.name;

        this.processForm.patchValue({
            name: tag.name,
            description: tag.description,
            process_type: process_type.id,
            unit: unit?.description,
            opc_address: opc_address,
            opc_server: opcServer?.name,
            min: min,
            max: max,
        });

        this.processForm.get('process_type').disable();
        this.process = this.data;
    }

    inputTypeChange(): Promise<void> {
        return new Promise((resolve) => {
            this.inputType = this.isLimitConst ? 'number' : 'text';
            setTimeout(() => resolve(), 0);
        });
    }

    async getDependencies() {
        const { process_type, tag } = this.data;
        const processType = process_type.name;
        switch (processType) {
            case VariableTypeEnum.DIGITAL:
                this.disableMinMax();
                break;
            case VariableTypeEnum.LIMIT:
                this.isLimit = true;
                break;
            case VariableTypeEnum.ANALOGIC:
                const AVGtagName = VariableTypeEnum.AVG + tag.name;
                const avgTag = await this.tagService.getTagByName(AVGtagName).toPromise();
                this.AVGTag = avgTag;
                break;
            case VariableTypeEnum.SETPOINT:
                const tagDependencies = await this.tagService.getTagDependenciesConstants(tag.id).toPromise();

                this.dependencies = tagDependencies.filter(({ max, min }) => max != min);
                break;
        }
    }

    close() {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const conf = this.defaultDialog;
        this.transformDataToDto();

        if (this.processForm.errors?.minMaxInvalid) {
            this.openDialog({
                component: PopUpMessagesComponent,
                width: 'auto',
                height: 'auto',
                panelClass: 'pop-up-dialog-container',
                data: {
                    imageUrl: this.errorIcon,
                    title: this.validationError,
                    subTitle: this.minMaxValidationErrorMessage,
                    typePopUp: this.informationPopUpType,
                },
            });
            return;
        }

        if (!this.processForm.valid) {
            this.openDialog({
                component: PopUpMessagesComponent,
                width: 'auto',
                height: 'auto',
                panelClass: 'pop-up-dialog-container',
                data: {
                    imageUrl: this.errorIcon,
                    title: this.invalidFormMessage,
                    typePopUp: this.informationPopUpType,
                },
            });
            return;
        }

        if (!this.validateName()) {
            this.openDialog({
                component: PopUpMessagesComponent,
                width: 'auto',
                height: 'auto',
                panelClass: 'pop-up-dialog-container',
                data: {
                    imageUrl: this.errorIcon,
                    title: this.restrictNameMessage,
                    typePopUp: this.informationPopUpType,
                },
            });
            return;
        }

        if (!this.validateLimits()) {
            this.openDialog({
                component: PopUpMessagesComponent,
                width: 'auto',
                height: 'auto',
                panelClass: 'pop-up-dialog-container',
                data: {
                    imageUrl: this.errorIcon,
                    title: this.invalidLimits,
                    typePopUp: this.informationPopUpType,
                },
            });
            return;
        }
        this.isEditing ? this.handleEditingProcess(conf) : this.handleNewProcess();
    }

    private async handleNewProcess(): Promise<void> {
        if (!this.user.permissions.canUpdate) {
            return;
        }
        this.startLoader('Salvando entrada, aguarde...');
        this.processService.newProcess(this.process).subscribe({
            next: (process) => {
                this.process = process;
                this.isEditing = true;
                //TODO: Implement here flag to reload quarterback.
                this.openDialog({
                    component: PopUpMessagesComponent,
                    width: 'auto',
                    height: 'auto',
                    panelClass: 'pop-up-dialog-container',
                    data: {
                        imageUrl: this.successIcon,
                        title: MessagesEnum.inputRegisterSuccessMessage,
                        typePopUp: this.informationPopUpType,
                    },
                });
                this.stopLoader();
            },
            error: (error) => {
                console.error('Error: ', error);
                this.openDialog({
                    component: PopUpMessagesComponent,
                    width: 'auto',
                    height: 'auto',
                    panelClass: 'pop-up-dialog-container',
                    data: {
                        imageUrl: this.errorIcon,
                        title: MessagesEnum.inputRegisterErrorMessage,
                        subTitle: 'Não foi possível cadastrar entrada.',
                        optionalText: 'Tente novamente mais tarde.',
                        typePopUp: this.informationPopUpType,
                    },
                });
                this.stopLoader();
            },
        });
    }

    async handleEditingProcess(conf: any): Promise<void> {
        if (!this.user.permissions.canUpdate) {
            return;
        }
        // if (this.data.process_type.name === VariableTypeEnum.ANALOGIC) {
        //     if (!this.validateAnalogicLimits()) {
        //         conf.data.message = `Os limites mínimo e máximo da entrada devem contemplar o mínimo e máximo Fuzzy, sendo estes ${this.AVGTag.min} e ${this.AVGTag.max} respectivamente`;
        //         this.openDialog(conf);
        //         return;
        //     }
        // } else
        if (this.data.process_type.name === VariableTypeEnum.SETPOINT) {
            if (!this.validateSPlimits()) {
                conf.data.message = `Os limites mínimo e máximo da entrada devem contemplar os limites de atuação do Setpoint, sendo estes ${this.SPmin} e ${this.SPmax} respectivamente`;
                this.openDialog(conf);
                return;
            }
        }
        this.startLoader('Atualizando entrada, aguarde...');
        this.processService.editProcess(this.process).subscribe({
            next: (process) => {
                this.process = process;
                //TODO: Implement here flag to reload quarterback.
                this.openDialog({
                    component: PopUpMessagesComponent,
                    width: 'auto',
                    height: 'auto',
                    panelClass: 'pop-up-dialog-container',
                    data: {
                        imageUrl: this.successIcon,
                        title: MessagesEnum.inputEditSuccessMessage,
                        typePopUp: this.informationPopUpType,
                    },
                });
                this.stopLoader();
            },
            error: (error) => {
                console.error(error);
                this.openDialog({
                    component: PopUpMessagesComponent,
                    width: 'auto',
                    height: 'auto',
                    panelClass: 'pop-up-dialog-container',
                    data: {
                        imageUrl: this.errorIcon,
                        title: MessagesEnum.inputEditErrorMessage,
                        subTitle: 'Não foi possível editar entrada.',
                        optionalText: 'Tente novamente mais tarde.',
                        typePopUp: this.informationPopUpType,
                    },
                });
                this.stopLoader();
            },
        });
    }

    transformDataToDto() {
        let formData = this.processForm.getRawValue();
        this.process.opc_address = formData.opc_address;
        if (formData.opc_server) {
            const opcServer = this.opcServers.find((OpcServer) => OpcServer.name === formData.opc_server);
            this.process.opcServerId = opcServer.id;
        }
        this.process.unit = this.units.find((unit) => unit.description == formData.unit);
        if (!this.process.unit) {
            this.processForm.patchValue({ unit: '' });
        }
        this.process.name = formData.name;
        this.process.tag.name = formData.name;
        this.process.tag.description = formData.description;

        if (this.isLimitConst) {
            this.process.tag.max = formData.max;
            this.process.tag.min = formData.min;
            this.process.tagMin = null;
            this.process.tagMax = null;
        } else {
            this.process.tagMin = new Tag();
            this.process.tagMax = new Tag();
            this.process.tagMin.name = formData.min;
            this.process.tagMax.name = formData.max;
            this.process.tag.max = 0;
            this.process.tag.min = 0;
        }
        this.process.process_type = this.processTypes.find((process) => process.id == formData.process_type);
    }

    validateSPlimits() {
        if (this.dependencies.length == 0 || !this.isLimitConst) {
            return true;
        }
        let rawMax = this.dependencies.map((tag) => tag.max);
        this.SPmax = rawMax.reduce((a, b) => Math.max(a, b));
        let rawMin = this.dependencies.map((tag) => tag.min);
        this.SPmin = rawMin.reduce((a, b) => Math.min(a, b));
        let formData = this.processForm.getRawValue();
        let max = formData.max;
        let min = formData.min;
        if (max >= this.SPmax && min <= this.SPmin) {
            this.clearLimitsError();
            return true;
        } else {
            if (max < this.SPmax) {
                this.processForm.controls['max'].setErrors({ incorrect: true });
            }
            if (min > this.SPmin) {
                this.processForm.controls['min'].setErrors({ incorrect: true });
            }
            return false;
        }
    }

    validateAnalogicLimits() {
        if (!this.isLimitConst) {
            return true;
        }
        let formData = this.processForm.getRawValue();
        let max = formData.max;
        let min = formData.min;
        if (max >= this.AVGTag.max && min <= this.AVGTag.min) {
            this.clearLimitsError();
            return true;
        } else {
            if (max < this.AVGTag.max) {
                this.processForm.controls['max'].setErrors({ incorrect: true });
            }
            if (min > this.AVGTag.min) {
                this.processForm.controls['min'].setErrors({ incorrect: true });
            }
            return false;
        }
    }

    validateName() {
        let name = this.processForm.get('name').value;
        if (
            name.startsWith(VariableTypeEnum.GRD) ||
            name.startsWith(VariableTypeEnum.AVG) ||
            name.startsWith(VariableTypeEnum.STD)
        ) {
            return false;
        } else {
            return true;
        }
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (
                options.data.message == this.successMessage ||
                options.data.message || options.data.title == this.deleteMessage ||
                options.data.title == MessagesEnum.inputRegisterSuccessMessage ||
                options.data.title == MessagesEnum.inputEditSuccessMessage
            ) {
                this.dialogRef.close(this.process);
            } else if (options.data.message == this.invalidLimits) {
                this.clearLimitsError();
            }
        });
    }

    clearLimitsError() {
        this.processForm.controls['max'].setErrors(null);
        this.processForm.controls['min'].setErrors(null);
    }

    validateLimits() {
        let formData = this.processForm.getRawValue();
        if (+formData.min < +formData.max || this.isLimit || !this.isLimitConst) {
            return true;
        } else {
            this.processForm.controls['max'].setErrors({ incorrect: true });
            this.processForm.controls['min'].setErrors({ incorrect: true });
            return false;
        }
    }

    disableMinMax() {
        this.processForm.get('max').disable();
        this.processForm.get('min').disable();
        this.processForm.get('unit').disable();
        this.processForm.patchValue({
            unit: '-',
            max: 1,
            min: 0,
        });
    }

    setDigitalValues(typeId: any) {
        let type = this.processTypes.find((process) => process.id == typeId);
        if (type.name == VariableTypeEnum.LIMIT) {
            this.isLimit = true;
            this.processForm.controls['min'].setValidators([]);
            this.processForm.controls['max'].setValidators([]);
        } else {
            this.isLimit = false;
            this.processForm.controls['min'].setValidators([Validators.required]);
            this.processForm.controls['max'].setValidators([Validators.required]);
        }
        if (type.name == VariableTypeEnum.DIGITAL) {
            this.disableMinMax();
            this.previousTypeIsDigital = true;
        } else {
            this.processForm.get('max').enable();
            this.processForm.get('min').enable();
            this.processForm.get('unit').enable();
            if (this.previousTypeIsDigital) {
                this.processForm.patchValue({
                    unit: '',
                    max: '',
                    min: '',
                });
            }
            this.previousTypeIsDigital = false;
        }
        this.showRadioButton = this.isToDisplayRadioButton(type.name);
    }

    deleteProcess() {
        this.openDialog({
            component: PopUpMessagesComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: '',
                title: 'Excluir Entrada',
                subTitle: 'Você tem certeza que deseja excluir entrada? ',
                optionalText: 'Esta ação não pode ser desfeita.',
                firstButtonText: 'EXCLUIR',
                secondButtonText: 'CANCELAR',
                typePopUp: 'deletePopUp',
            },
        });
        this.startLoader("Excluindo entrada, aguarde...")
        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (result) {
                this.processService.deleteProcess(this.process.id).subscribe({
                    next: (dependencies) => {
                        if (dependencies.length > 0) {
                            let dependencieNames = '';
                            dependencies.forEach((tag) => {
                                dependencieNames += tag.name + ', ';
                            });
                            dependencieNames = dependencieNames.slice(0, -2) + '.';
                            this.openDialog({
                                component: PopUpMessagesComponent,
                                width: 'auto',
                                height: 'auto',
                                panelClass: 'pop-up-dialog-container',
                                data: {
                                    imageUrl: this.errorIcon,
                                    title: 'Conflito ao deletar. Esta variável é usada em: ' + dependencieNames,
                                    typePopUp: this.informationPopUpType,
                                },
                            });
                            this.stopLoader()
                        } else {
                            this.process.id = null;
                            this.openDialog({
                                component: PopUpMessagesComponent,
                                width: 'auto',
                                height: 'auto',
                                panelClass: 'pop-up-dialog-container',
                                data: {
                                    imageUrl: this.successIcon,
                                    title: this.deleteMessage,
                                    typePopUp: this.informationPopUpType,
                                },
                            });
                            this.stopLoader()
                        }
                    },
                    error: (error) => {
                        console.error(error);
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            width: 'auto',
                            height: 'auto',
                            panelClass: 'pop-up-dialog-container',
                            data: {
                                imageUrl: this.errorIcon,
                                title: this.inputDeleteErrorMessage,
                                subTitle: 'Não foi possível excluir entrada.',
                                optionalText: 'Tente novamente mais tarde.',
                                typePopUp: 'titlePopUpInformationMessage',
                            },
                        });
                        this.stopLoader()
                    },
                });
            }
        });
    }

    resetMaxLimit() {
        if (!this.isLimitConst) {
            this.currentMaxLimit = this.processForm.get('max').value
                ? this.processForm.get('max').value
                : this.currentMaxLimit;
            this.processForm.patchValue({ max: '' });
        }
        // this.clearLimitsError();
    }

    setMaxLimit(tag) {
        let tagOption = this.limitOptions.find((limit) => limit.tag.name == tag);
        if (tagOption) {
            this.currentMaxLimit = tagOption.name;
        } else if (!this.isLimitConst) {
            this.processForm.patchValue({ max: this.currentMaxLimit });
        }
    }

    setMinLimit(tag) {
        let tagOption = this.limitOptions.find((limit) => limit.tag.name == tag);
        if (tagOption) {
            this.currentMinLimit = tagOption.name;
        } else if (!this.isLimitConst) {
            this.processForm.patchValue({ min: this.currentMinLimit });
        }
    }

    resetMinLimit() {
        if (!this.isLimitConst) {
            this.currentMinLimit = this.processForm.get('min').value
                ? this.processForm.get('min').value
                : this.currentMinLimit;
            this.processForm.patchValue({ min: '' });
        }
        // this.clearLimitsError();
    }

    setOpcServer(opcServer) {
        if (opcServer) {
            const opcServerOption = this.opcServers.find((element) => element.name == opcServer);
            if (opcServerOption) {
                this.processForm.patchValue({ opc_server: opcServerOption?.name });
            }
        } else {
            this.processForm.patchValue({ opc_server: this.currentOpcServer?.name });
        }
    }

    resetInputOpcServer() {
        if (this.isEditing) {
            this.currentOpcServer.name = this.processForm.get('opc_server').value
                ? this.processForm.get('opc_server').value
                : this.currentOpcServer.name;
        }
        this.processForm.patchValue({ opc_server: '' });
        this.processForm.controls['opc_server'].setErrors(null);
    }

    isToDisplayRadioButton(processType) {
        return [VariableTypeEnum.ANALOGIC, VariableTypeEnum.SETPOINT, VariableTypeEnum.MANIPULATED_VARIABLE].includes(
            processType
        );
    }

    minMaxValidator(control: AbstractControl) {
        const min = control.get('min');
        const max = control.get('max');
        if (min !== null && max !== null && +min?.value >= +max?.value && (min?.dirty || max?.dirty)) {
            min?.markAsTouched();
            max?.markAsTouched();
            min?.setErrors({ ...min.errors, minMaxInvalid: true });
            max?.setErrors({ ...max.errors, minMaxInvalid: true });
            return { ...control.errors, minMaxInvalid: true };
        }
        min?.updateValueAndValidity({ onlySelf: true });
        max?.updateValueAndValidity({ onlySelf: true });
        return null;
    }

    startLoader(message: string) {
        this.isLoading = true;
        this.loaderMessage = message;
    }

    stopLoader() {
        this.isLoading = false;
        this.loaderMessage = '';
    }
}
