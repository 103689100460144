import { OpcServer } from 'src/app/shared/models/views-models/opcServer.model';
import { OpcServerService } from './../../../service/views-services/opcServer.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { ProjectSettings } from 'src/app/shared/models/views-models/projectSettings.model';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { getUserData } from 'src/app/shared/utils/userRole';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { getProject } from 'src/app/shared/utils/projectUtils';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { PopUpMessagesComponent } from '../../pop-up-messages/pop-up-messages.component';

@Component({
    selector: 'app-project-settings',
    templateUrl: './project-settings.component.html',
    styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent implements OnInit {
    projectSettingsForm: UntypedFormGroup;
    projectSettings: ProjectSettings;
    cycles_array: number[] = [];
    on_off: string;
    user: any;
    isMvBeingUsed: boolean;
    dialogRefMsg: any;
    opcServers: OpcServer[];
    loadFormView: boolean;
    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: MessagesEnum.SuccessMessage,
        },
    };

    successImg = 'assets/images/status/successMessage.svg';
    errorImg = 'assets/images/status/errorMessage.svg';

    isLoading: boolean = false;
    loaderMessage: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private projectService: ProjectService,
        private opcServerService: OpcServerService,
        public dialog: MatDialog,
        private currentProjectService: CurrentProjectService,
        public dialogRef: MatDialogRef<ProjectSettingsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProjectSettings
    ) {}

    async ngOnInit() {
        this.user = getUserData();
        this.projectSettings = this.data;
        this.isMvBeingUsed = this.data.mvControlBeingUsedBySp;
        this.initForm();
        await this.loadOpcServers();
    }

    async loadOpcServers() {
        const opcServers = await this.opcServerService.getAllOpcServers().toPromise();
        this.opcServers = opcServers.map((opcServer, i) => ({
            ...opcServer,
        }));
        this.createEditForm();
        this.setLabelNames();
    }

    createEditForm() {
        const projectOnOpcServer = this.opcServers.find(({ id }) => id === this.projectSettings.projectOnOpcServerId);
        const projectStatusOpcServer = this.opcServers.find(
            ({ id }) => id === this.projectSettings.projectStatusOpcServerId
        );
        const projectAptOpcServer = this.opcServers.find(({ id }) => id === this.projectSettings.projectAptOpcServerId);
        const watchdogOpcServer = this.opcServers.find(({ id }) => id === this.projectSettings.watchdogOpcServerId);
        this.projectSettingsForm.patchValue({
            project_on: this.projectSettings.projectOnWebapp,
            project_on_opc_server: projectOnOpcServer.name,
            project_on_opc: this.projectSettings.projectOnOpc,
            process_status_opc: this.projectSettings.projectStatusOpc,
            process_status_opc_server: projectStatusOpcServer.name,
            project_apt_opc: this.projectSettings.projectAptOpc,
            project_apt_opc_server: projectAptOpcServer.name,
            mv_control: this.projectSettings.mvControl,
            cycle_time: this.projectSettings.cycleTime,
            watchdog: this.projectSettings.watchdog,
            watchdog_opc: this.projectSettings.watchdogOpc,
            watchdog_opc_server: watchdogOpcServer.name,
        });
        if (!this.user.permissions.canUpdate || this.isShowForm()) {
            this.projectSettingsForm.disable();
        }
    }

    initForm() {
        this.projectSettingsForm = this.formBuilder.group({
            project_on: [null, [Validators.required]],
            project_on_opc: [null, [Validators.required]],
            project_on_opc_server: [null, [Validators.required]],
            process_status_opc: [null, [Validators.required]],
            process_status_opc_server: [null, [Validators.required]],
            project_apt_opc: [null, [Validators.required]],
            project_apt_opc_server: [null, [Validators.required]],
            mv_control: [null, [Validators.required]],
            cycle_time: [null, [Validators.required, Validators.min(1)]],
            watchdog: [null, [Validators.required, Validators.min(1)]],
            watchdog_opc: [null, [Validators.required]],
            watchdog_opc_server: [null, [Validators.required]],
        });
        this.setDefaultValues();
        this.loadFormView = false;
    }

    checkIfDisableIsAllowed() {
        if (this.isMvBeingUsed) {
            this.defaultDialog.data.message = MessagesEnum.existsMV;
            this.openDialog(this.defaultDialog);
            this.dialogRefMsg.afterClosed().subscribe(() => {
                if ([MessagesEnum.existsMV.valueOf()].includes(this.defaultDialog.data.message)) {
                    this.projectSettingsForm.patchValue({ mv_control: true });
                }
            });
        }
    }

    setDefaultValues() {
        this.projectSettingsForm.patchValue({
            project_on: false,
            mv_control: false,
        });
    }

    onSubmit() {
        if (this.projectSettingsForm.valid) {
            this.startLoader("Salvando configurações, aguarde...");
            this.transformDataToDto();
            this.projectService.saveProjectSettings(this.projectSettings).subscribe({
                next: (response) => {
                    this.projectSettings = response;
                    this.openDialog({
                        component: PopUpMessagesComponent,
                        width: 'auto',
                        height: 'auto',
                        panelClass: 'pop-up-dialog-container',
                        data: {
                            title: MessagesEnum.projectSettingsEditSuccessMessage,
                            imageUrl: this.successImg,
                            typePopUp: 'titlePopUpInformationMessage',
                        },
                    });
                    //TODO: Implement here flag to reload quarterback.
                    this.stopLoader();
                },
                error: (error) => {
                    console.log('Error: ', error);
                    this.openDialog({
                        component: PopUpMessagesComponent,
                        width: 'auto',
                        height: 'auto',
                        panelClass: 'pop-up-dialog-container',
                        data: {
                            imageUrl: this.errorImg,
                            title: MessagesEnum.projectSettingsEditErrorMessage,
                            subTitle: 'Não foi possível salvar as configurações do projeto',
                            optionalText: ' Tente novamente mais tarde.',
                            typePopUp: 'titlePopUpInformationMessage',
                        },
                    });
                    this.stopLoader();
                },
            });
        } else {
            this.defaultDialog.data.message = MessagesEnum.invalidFormMessage;
            this.openDialog(this.defaultDialog);
        }
    }

    transformDataToDto() {
        const formData = this.projectSettingsForm.getRawValue();
        const projectOnOpcServer = this.opcServers.find(
            (opcServer) => opcServer.name === formData.project_on_opc_server
        );
        const projectStatusOpcServer = this.opcServers.find(
            (opcServer) => opcServer.name === formData.process_status_opc_server
        );
        const projectAptOpcServer = this.opcServers.find(
            (opcServer) => opcServer.name === formData.project_apt_opc_server
        );
        const watchdogOpcServer = this.opcServers.find((opcServer) => opcServer.name === formData.watchdog_opc_server);
        this.projectSettings.projectOnWebapp = formData.project_on;
        this.projectSettings.mvControl = formData.mv_control;
        this.projectSettings.cycleTime = formData.cycle_time;
        this.projectSettings.projectOnOpc = formData.project_on_opc;
        this.projectSettings.projectStatusOpc = formData.process_status_opc;
        this.projectSettings.projectAptOpc = formData.project_apt_opc;
        this.projectSettings.watchdog = formData.watchdog;
        this.projectSettings.watchdogOpc = formData.watchdog_opc;
        this.projectSettings.projectOnOpcServerId = projectOnOpcServer.id;
        this.projectSettings.projectStatusOpcServerId = projectStatusOpcServer.id;
        this.projectSettings.projectAptOpcServerId = projectAptOpcServer.id;
        this.projectSettings.watchdogOpcServerId = watchdogOpcServer.id;
    }

    setLabelNames() {
        let formData = this.projectSettingsForm.getRawValue();
        this.on_off = formData.project_on ? 'Ligado' : 'Desligado';
    }

    projectStateChange() {
        this.defaultDialog.data.message = MessagesEnum.changeProjectStatus;
        this.openDialog(this.defaultDialog);
        this.dialogRefMsg.afterClosed().subscribe((response) => {
            if (response) {
                this.setLabelNames();
            }
        });
    }

    setOpcServer(opcServer, formInputName) {
        let currentOpcServerInput: OpcServer;
        switch (formInputName) {
            case 'project_on_opc_server':
                currentOpcServerInput = this.opcServers.find(
                    ({ id }) => id === this.projectSettings.projectOnOpcServerId
                );
                break;
            case 'process_status_opc_server':
                currentOpcServerInput = this.opcServers.find(
                    ({ id }) => id === this.projectSettings.projectStatusOpcServerId
                );
                break;
            case 'project_apt_opc_server':
                currentOpcServerInput = this.opcServers.find(
                    ({ id }) => id === this.projectSettings.projectAptOpcServerId
                );
                break;
            case 'watchdog_opc_server':
                currentOpcServerInput = this.opcServers.find(
                    ({ id }) => id === this.projectSettings.watchdogOpcServerId
                );
                break;
            default:
                break;
        }
        const existOpcServer = this.opcServers.some(({ name }) => name === opcServer);
        if (this.projectSettingsForm.get(formInputName).value !== currentOpcServerInput.name && !existOpcServer) {
            this.projectSettingsForm.get(formInputName).setValue(currentOpcServerInput.name);
        }
    }

    resetInputOpcServer(formInputName) {
        this.projectSettingsForm.get(formInputName)?.reset('');
    }

    close(response) {
        this.dialogRef.close(response);
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });
        this.dialogRefMsg.afterClosed().subscribe(() => {
            if ([MessagesEnum.SuccessEditMessage.valueOf()].includes(this.defaultDialog.data.message)) {
                this.close(true);
            }
        });
    }

    isShowForm() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType !== 'BUILDING';
    }

    isFieldInvalid(fieldName: string) {
        return (
            this.projectSettingsForm.get(fieldName)?.invalid &&
            (this.projectSettingsForm.get(fieldName)?.dirty || this.projectSettingsForm.get(fieldName)?.touched)
        );
    }

    startLoader(message?: string) {
        this.isLoading = true;
        this.loaderMessage = message;
    }

    stopLoader() {
        this.isLoading = false;
        this.loaderMessage = '';
    }
}
