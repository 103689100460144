import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { Project } from 'src/app/shared/models/views-models/project.model';
import { LocalStorageService } from 'src/app/shared/service/views-services/localstorage.service';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { PopUpMessagesComponent } from '../../pop-up-messages/pop-up-messages.component';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';

@Component({
    selector: 'app-project-dialog',
    templateUrl: './project-dialog.component.html',
    styleUrls: ['./project-dialog.component.scss'],
})
export class ProjectDialogComponent implements OnInit {
    isEditing = false;
    projectDefault = false;
    projectForm: UntypedFormGroup;
    project: Project = new Project();

    dialogRefMsg: any;
    defaultDialog = {
        component: PopUpMessagesComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            imageUrl: '',
            title: '',
            subTitle: '',
            typePopUp: '',
        },
    };

    succesIcon: string = 'assets/images/status/successMessage.svg';
    errorIcon: string = 'assets/images/status/errorMessage.svg';

    isLoading: boolean;
    loaderMessage: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private projectService: ProjectService,
        private lsService: LocalStorageService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<ProjectDialogComponent>,
        public currentProjectService: CurrentProjectService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.initForm();
        if (this.data) {
            this.createEditForm();
        }
    }

    initForm() {
        this.projectForm = this.formBuilder.group({
            name: [null, [Validators.required]],
            description: [null],
            area: [null],
        });
        this.getCurrentProjectVersionType() === 'RUNNING' && this.projectForm.disable();
    }

    createEditForm() {
        this.isEditing = true;
        this.projectDefault = this.data.projectDefault;
        this.projectForm.patchValue({
            name: this.data.name,
            description: this.data.description,
            area: this.data.area,
        });
    }

    setDefaultProject() {
        this.projectDefault = true;
    }

    onSubmit() {
        if (this.projectForm.valid) {
            this.transformDataToDto();
            if (!this.isEditing) {
                this.startLoader("Salvando projeto, aguarde...");
                this.projectService.newProject(this.project).subscribe({
                    next: (response) => {
                        this.project = response;
                        this.projectDefault = this.project.projectDefault;
                        if (this.projectDefault) {
                            this.lsService.reset(this.project);
                        }
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            //TODO Aplicar tamanhos fixos, porém para isso é necessário corrigir a centralização dos elementos internos dos pop-ups
                            //TODO width: '32.5rem',
                            //TODO height: '13.5rem',
                            data: {
                                imageUrl: this.succesIcon,
                                title: MessagesEnum.projectRegisterSuccessMessage,
                                typePopUp: 'titlePopUpInformationMessage',
                            },
                        });
                        this.stopLoader();
                    },
                    error: () => {
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            //TODO Aplicar a largura fixa relativa ao tamanho da fonte, porém para isso é necessário corrigir a centralização do componente de pop-up.
                            //TODO width: '32.5rem'.
                            data: {
                                imageUrl: this.errorIcon,
                                title: MessagesEnum.projectRegisterErrorMessage,
                                subTitle: `Não foi possível cadastrar o projeto ${this.projectForm.getRawValue().name}.`,
                                optionalText: 'Tente novamente mais tarde.',
                                typePopUp: 'titlePopUpInformationMessage'
                            },
                        });
                        this.stopLoader();
                    },
                });
            } else {
                this.startLoader("Atualizando projeto, aguarde...");
                this.project.id = this.data.id;
                this.projectService.updateProject(this.project).subscribe({
                    next: (response) => {
                        this.project = response;
                        this.projectDefault = this.project.projectDefault;
                        if (this.projectDefault) {
                            this.lsService.reset(this.project);
                        }
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            //TODO Aplicar a largura fixa relativa ao tamanho da fonte, porém para isso é necessário corrigir a centralização do componente de pop-up.
                            //TODO width: '32.5rem'.
                            data: {
                                imageUrl: this.succesIcon,
                                title: MessagesEnum.projectEditingSuccessMessage,
                                typePopUp: 'titlePopUpInformationMessage',
                            },
                        });
                        this.dialogRefMsg.afterClosed().subscribe(() => {
                            if (this.currentProjectService.getCurrentProject().id === this.project.id) {
                                this.currentProjectService.setCurrentProject(this.project);
                            }
                        });
                        this.stopLoader();
                    },
                    error: () => {
                        this.openDialog({
                            component: PopUpMessagesComponent,
                            //TODO Aplicar a largura fixa relativa ao tamanho da fonte, porém para isso é necessário corrigir a centralização do componente de pop-up.
                            //TODO width: '32.5rem'.
                            data: {
                                imageUrl: this.errorIcon,
                                title: MessagesEnum.projectEditingErrorMessage,
                                subTitle: `Não foi possível atualizar o projeto ${this.data.name}.`,
                                optionalText: 'Tente novamente mais tarde.',
                                typePopUp: 'titlePopUpInformationMessage'
                            },
                        });
                        this.stopLoader();
                    },
                });
            }
        } else {
            this.openDialog({
                component: PopUpMessagesComponent,
                //TODO Aplicar a largura fixa relativa ao tamanho da fonte, porém para isso é necessário corrigir a centralização do componente de pop-up.
                //TODO width: '32.5rem'.
                data: {
                    imageUrl: this.errorIcon,
                    title: MessagesEnum.invalidFormMessage,
                    typePopUp: 'titlePopUpInformationMessage'
                },
            });
        }
    }

    transformDataToDto() {
        let formData = this.projectForm.getRawValue();
        this.project.name = formData.name;
        this.project.description = formData.description;
        this.project.area = formData.area;
        this.project.projectDefault = this.projectDefault;
    }

    close() {
        this.dialogRef.close(true);
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });
        this.dialogRefMsg.afterClosed().subscribe(() => {
            const containsMessage: boolean = [
                MessagesEnum.SuccessEditMessage.valueOf(),
                MessagesEnum.SuccessMessage.valueOf(),
                MessagesEnum.projectRegisterSuccessMessage.valueOf(),
                MessagesEnum.projectEditingSuccessMessage.valueOf(),
            ].includes(this.defaultDialog.data.title || options.data.title);

            if(containsMessage){
                this.close();
            }
        });
    }

    isFieldInvalid(field: string) {
        return (
            this.projectForm.get(field)?.invalid &&
            (this.projectForm.get(field)?.dirty || this.projectForm.get(field)?.touched)
        );
    }

    startLoader(message?: string) {
        this.isLoading = true;
        this.loaderMessage = message;
    }

    stopLoader() {
        this.isLoading = false;
        this.loaderMessage = '';
    }
    
    getCurrentProjectVersionType(): string {
        return this.data? this.data.versionType : '';
    }
}
