import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MentionModule } from 'angular-mentions';
import { NgxEchartsModule } from 'ngx-echarts';
import { IconsModule } from '../icons/icons.module';
import { DataTreatmentComponent } from '../views/data-treatment/data-treatment.component';
import { ProjectsListComponent } from '../views/projects-list/projects-list.component';
import { RulesTrackingComponent } from '../views/rules-tracking/rules-tracking.component';
import { UsersListComponent } from '../views/users-list/users-list.component';
import { HistorySiderComponent } from './components/design/history-sider/history-sider.component';
import { IhmCardHeaderComponent } from './components/design/ihm-card-header/ihm-card-header.component';
import './components/design/prism/prism-python.js';
import { PrismComponent } from './components/design/prism/prism.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from './components/dialogs/delete-dialog/delete-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AdminLayoutComponent } from './components/layout/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/layout/auth-layout/auth-layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { AddAntecedentComponent } from './components/views-components/add-antecedent/add-antecedent.component';
import { AddFuzzyInputComponent } from './components/views-components/add-fuzzy-input/add-fuzzy-input.component';
import { AddProcessOutputComponent } from './components/views-components/add-process-output/add-process-output.component';
import { AddWeightedDialogComponent } from './components/views-components/add-weighted-dialog/add-weighted-dialog.component';
import { AdminSettingsComponent } from './components/views-components/admin-settings/admin-settings.component';
import { CalculatedEquationsComponent } from './components/views-components/calculated-equations/calculated-equations.component';
import { EquationAddEditComponent } from './components/views-components/calculated-equations/equation-add-edit/equation-add-edit.component';
import { ControlMatrixComponent } from './components/views-components/control-matrix/control-matrix.component';
import { MatrixComponent } from './components/views-components/control-matrix/matrix/matrix.component';
import { DateNavigatorComponent } from './components/views-components/date-navigator/date-navigator.component';
import { FormulaDialogComponent } from './components/views-components/formula-dialog/formula-dialog.component';
import { ProcessInputComponent } from './components/views-components/process-input/process-input.component';
import { ProjectCardComponent } from './components/views-components/project-card/project-card.component';
import { ProjectDialogComponent } from './components/views-components/project-dialog/project-dialog.component';
import { ProjectSettingsComponent } from './components/views-components/project-settings/project-settings.component';
import { TableControlRuleComponent } from './components/views-components/table-control-rule/table-control-rule.component';
import { TableInputsComponent } from './components/views-components/table-inputs/table-inputs.component';
import { TableOutputsComponent } from './components/views-components/table-outputs/table-outputs.component';
import { TableSetpointsComponent } from './components/views-components/table-setpoints/table-setpoints.component';
import { TableWeightedComponent } from './components/views-components/table-weighted/table-weighted.component';
import { UserDialogComponent } from './components/views-components/user-dialog/user-dialog.component';
import { AuthGuard } from './service/auth/auth.guard';
import { AuthInterceptor } from './service/auth/auth.interceptor';
import { LayoutService } from './service/layout/layout.service';
import { MessageService } from './service/message/message.service';
import { NavigationService } from './service/navigation/navigation.service';
import { QuarterbackService } from './service/quarterback/quarterback.service';
import { ThemeService } from './service/theme/theme.service';
import { AdminSettingsService } from './service/views-services/adminSettings.service';
import { BaseMatrixService } from './service/views-services/base-matrix.service';
import { CalculationService } from './service/views-services/calculation.service';
import { CategoriesService } from './service/views-services/categories.service';
import { CurveTypeService } from './service/views-services/curveType.service';
import { EquationService } from './service/views-services/equation.service';
import { FuzzyService } from './service/views-services/fuzzy.service';
import { FuzzyCalculationService } from './service/views-services/fuzzyCalculation.service';
import { FuzzySetService } from './service/views-services/fuzzySet.service';
import { LocalStorageService } from './service/views-services/localstorage.service';
import { OpcServerService } from './service/views-services/opcServer.service';
import { ProcessInputService } from './service/views-services/process.service';
import { ProcessTypeService } from './service/views-services/processType.service';
import { ProjectService } from './service/views-services/project.service';
import { SetpointCalculationService } from './service/views-services/setpointCalculation.service';
import { SideNavService } from './service/views-services/sideNavService.service';
import { SpConditionsValuesService } from './service/views-services/spConditionValue.service';
import { TagService } from './service/views-services/tag.service';
import { TagValueService } from './service/views-services/tagValue.service';
import { UnitService } from './service/views-services/unit.service';
import { UserService } from './service/views-services/user.service';
import { WeightedService } from './service/views-services/weighted.service';
import { WeightedEntryService } from './service/views-services/weightedEntry.service';
import { getPtPaginatorIntl } from './utils/pt-paginator-intl';
import { LoaderComponent } from './components/loader/loader.component';
import { CycleInfoService } from './service/views-services/cycle-info.service';
import { SetpointChartComponent } from '../views/setpoints-tracking/setpoint-chart/setpoint-chart.component';
import { SidePanelInfoComponent } from '../views/setpoints-tracking/side-panel-info/side-panel-info.component';
import { SetpointsTrackingComponent } from '../views/setpoints-tracking/setpoint-tracking/setpoints-tracking.component';
import { SetpointsTrackingSettingsComponent } from '../views/setpoints-tracking/setpoint-tracking-settings/setpoints-tracking-settings.component';
import { CycleInformationComponent } from '../views/setpoints-tracking/cycle-information/cycle-information.component';
import { CycleInformationTreeComponent } from '../views/setpoints-tracking/cycle-information-tree/cycle-information-tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { VersionButtonsComponent } from './components/version-buttons/version-buttons.component';
import { PopUpMessagesComponent } from './components/pop-up-messages/pop-up-messages.component';
import { MomentModule } from 'ngx-moment';

const classesToInclude = [
    CycleInformationTreeComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    SidenavComponent,
    SidebarComponent,
    TopbarComponent,
    HeaderComponent,
    TableWeightedComponent,
    TableSetpointsComponent,
    TableInputsComponent,
    TableOutputsComponent,
    TableControlRuleComponent,
    ProcessInputComponent,
    AddProcessOutputComponent,
    AddAntecedentComponent,
    ConfirmDialogComponent,
    DeleteDialogComponent,
    ControlMatrixComponent,
    MatrixComponent,
    AddWeightedDialogComponent,
    FormulaDialogComponent,
    DataTreatmentComponent,
    AdminSettingsComponent,
    RulesTrackingComponent,
    SidePanelInfoComponent,
    UsersListComponent,
    UserDialogComponent,
    ProjectsListComponent,
    ProjectSettingsComponent,
    ProjectDialogComponent,
    ProjectCardComponent,
    SetpointsTrackingComponent,
    CycleInformationComponent,
    DateNavigatorComponent,
    EquationAddEditComponent,
    IhmCardHeaderComponent,
    SetpointChartComponent,
];

const servicesToInclude = [
    OpcServerService,
    CurveTypeService,
    FuzzySetService,
    CategoriesService,
    ProcessInputService,
    FuzzyService,
    FuzzyCalculationService,
    ProcessTypeService,
    UnitService,
    UserService,
    WeightedService,
    EquationService,
    WeightedEntryService,
    TagService,
    SetpointCalculationService,
    BaseMatrixService,
    CalculationService,
    TagValueService,
    AdminSettingsService,
    QuarterbackService,
    ProjectService,
    LocalStorageService,
    SpConditionsValuesService,
    SideNavService,
    CycleInfoService,
    PopUpMessagesComponent
];

@NgModule({
    declarations: [
        classesToInclude,
        AddFuzzyInputComponent,
        FooterComponent,
        CalculatedEquationsComponent,
        EquationAddEditComponent,
        PrismComponent,
        HistorySiderComponent,
        SetpointsTrackingSettingsComponent,
        LoaderComponent,
        VersionButtonsComponent,
        PopUpMessagesComponent
    ],
    imports: [
        MatExpansionModule,
        MatChipsModule,
        IconsModule,
        CommonModule,
        RouterModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatSortModule,
        MatCheckboxModule,
        MatDividerModule,
        MatToolbarModule,
        MatMenuModule,
        MatButtonModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatTableModule,
        MatPaginatorModule,
        MatInputModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatGridListModule,
        MatSnackBarModule,
        MatDialogModule,
        MatSelectModule,
        MatOptionModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatTabsModule,
        MentionModule,
        NgxEchartsModule.forRoot({ echarts: chartModule }),
        MatDatepickerModule,
        MatNativeDateModule,
        MatTreeModule,
        MomentModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: MatPaginatorIntl, useValue: getPtPaginatorIntl() },
        servicesToInclude,
        AuthGuard,
        MessageService,
        ThemeService,
        LayoutService,
        NavigationService,
    ],
    exports: [classesToInclude],
})
export class SharedModule {}

export function chartModule(): any {
    return import('echarts');
}
