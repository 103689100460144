import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, filter, interval, lastValueFrom, map, of, switchMap, timer } from 'rxjs';
import { Project } from '../../models/views-models/project.model';
import { Views } from '../../utils/views-urls';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { LocalStorageService } from './localstorage.service';
import { formatDateObj } from '../../utils/date.utils';
import { ProjectService } from './project.service';
import { ProjectStatus } from '../../models/views-models/projectStatus.model';

const API = environment.serverUrl;
@Injectable({
    providedIn: 'root',
})
export class CurrentProjectService {
    public $project: BehaviorSubject<Project> = new BehaviorSubject<Project>(null);
    public $projectStatus: BehaviorSubject<ProjectStatus[]> = new BehaviorSubject<ProjectStatus[]>([]);
    public allProjects: Array<Project> = [];
    public isProjectStatusReady: Boolean = false;

    constructor(private router: Router, private httpClient: HttpClient, private projectService: ProjectService) {
        this._initToObserveProjectStatus();
    }

    public async updateAllProjects() {
        this.isProjectStatusReady = false;
        const projects = await lastValueFrom(this.projectService.getAllProjects());
        this.allProjects = projects.filter((p) => p.versionType == 'RUNNING');
        await this.updateAllProjectsStatus();
    }

    public async updateAllProjectsStatus() {
        let projectsStatus = [];
        for (const project of this.allProjects) {
            const projectStatus = await lastValueFrom(this.projectService.getProjectStatus(project.id));
            projectsStatus.push(projectStatus);
        }
        this.$projectStatus.next(projectsStatus);
        this.isProjectStatusReady = true;
    }

    public async updateCurrentProjectStatus() {
        const currentProject = this.getCurrentProject();
        if (currentProject && currentProject.versionType == 'RUNNING') {
            const currentProjectStatus = await lastValueFrom(this.projectService.getProjectStatus(currentProject.id));
            const projectsStatus = this.$projectStatus.value || [];
            const indexStatus = projectsStatus.findIndex(
                (p) => p?.project?.mainProjectId == currentProject?.mainProjectId
            );

            if (indexStatus >= 0) {
                projectsStatus[indexStatus] = currentProjectStatus;
            } else {
                projectsStatus.push(currentProjectStatus);
            }
            this.$projectStatus.next(projectsStatus);
        }
    }

    getCurrentProject(): Project {
        return this.$project.value;
    }

    isBuildingMode(): boolean {
        const project = this.getCurrentProject();
        return project?.versionType == 'BUILDING';
    }

    isRunningMode() {
        const project = this.getCurrentProject();
        return project?.versionType == 'RUNNING';
    }

    setCurrentProject(project: Project): void {
        this.$project.next(project);
        this.updateAllProjects();
    }

    hasCurrentProject(): boolean {
        return this.$project.value != null;
    }

    observeCurrentProject(): Observable<Project> {
        return this.$project.asObservable();
    }

    observeProjectStatus(): Observable<ProjectStatus[]> {
        return this.$projectStatus.asObservable();
    }

    getFromServer(): Observable<Project> {
        return this.httpClient.get<Response>(`${API}project/default`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const project: Project = response.data;
                return project;
            })
        );
    }

    getDefaultVersionFromServer(project: Project, versionType: 'BUILDING' | 'RUNNING'): Observable<Project> {
        return this.projectService.getProjectVersions(project.mainProjectId).pipe(
            switchMap((versions) => {
                if (versions.length == 0) {
                    return this.projectService.getDefaultProject();
                }

                const projectVersions: Project[] = versions
                    .map((v) => {
                        return { ...v, created: formatDateObj(new Date(v.created)) };
                    })
                    .filter((v) => v?.versionType == 'BUILDING' || v?.versionType == 'RUNNING');

                const defaultProject: Project = projectVersions.find((p) => p.versionType == versionType);

                return of(defaultProject);
            })
        );
    }

    updateFromServer() {
        this.getFromServer().subscribe((project) => {
            this.setCurrentProject(project);
        });
    }

    goHome() {
        this.router.navigate([Views.homePage], { onSameUrlNavigation: 'reload' });
    }

    goReload() {
        let reloadPage = this.router.url;
        const urlMapping = {
            [Views.saveControlRules.url]: Views.controlRules.url,
            [Views.saveSetpoint.url]: Views.setpointsList.url,
        };

        const matchedUrl = Object.keys(urlMapping).find((key) => reloadPage.includes(key));

        if (matchedUrl) {
            reloadPage = urlMapping[matchedUrl];
        }

        this.router.navigate([reloadPage], { onSameUrlNavigation: 'reload' });
    }

    private getHeaders(): HttpHeaders {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const token = currentUser?.token;
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
        });
        return headers;
    }

    private _initToObserveProjectStatus() {
        this._timerUpdateAllProjects();
        this._timerUpdateAllProjectsStatus();
        this._timerUpdateCurrentProjectStatus();
    }

    private _timerUpdateAllProjects() {
        timer(0, 60 * 10 * 1000).subscribe(async () => {
            await this.updateAllProjects();
        });
    }

    private _timerUpdateAllProjectsStatus() {
        timer(0, 60 * 1000).subscribe(async () => {
            await this.updateAllProjectsStatus();
        });
    }

    private _timerUpdateCurrentProjectStatus() {
        timer(0, 15 * 1000).subscribe(async () => {
            await this.updateCurrentProjectStatus();
        });
    }
}

@Injectable({ providedIn: 'root' })
export class ProjectResolver implements Resolve<Observable<Project>> {
    constructor(
        private currentProjectService: CurrentProjectService,
        private lsService: LocalStorageService,
        private projectService: ProjectService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
        if (this.currentProjectService.hasCurrentProject()) {
            return this.currentProjectService.observeCurrentProject();
        }

        //Busca do Storage de acorodo com o projeto selecionado
        if (this.lsService.hasDefaultProject()) {
            const currentProject = this.lsService.getDefaultProject();

            return this.projectService.getProjectVersions(currentProject.mainProjectId).pipe(
                switchMap((versions) => {
                    if (versions.length == 0) {
                        return this.projectService.getDefaultProject().pipe(
                            switchMap((defaultProject) => {
                                this.currentProjectService.setCurrentProject(defaultProject);
                                return of(defaultProject);
                            })
                        );
                    }

                    const projectVersions: Project[] = versions
                        .map((v) => {
                            return { ...v, created: formatDateObj(new Date(v.created)) };
                        })
                        .filter((v) => v?.versionType == 'BUILDING' || v?.versionType == 'RUNNING');

                    const defaultProject: Project =
                        projectVersions.find((p) => p.versionType == currentProject.versionType) || currentProject;

                    this.currentProjectService.setCurrentProject(defaultProject);

                    return of(defaultProject);
                })
            );
        }

        //Busca do backend
        return this.projectService.getDefaultProject().pipe(
            switchMap((defaultProject) => {
                this.currentProjectService.setCurrentProject(defaultProject);
                return of(defaultProject);
            })
        );
    }
}
