import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { CycleInfo } from 'src/app/shared/models/views-models/cycleInfo';
import { FuzzyCalculation } from 'src/app/shared/models/views-models/fuzzyCalculation.model';
import { IncrementSetpoint } from 'src/app/shared/models/views-models/incrementSetpoint.model';
import { OutputConditionValue } from 'src/app/shared/models/views-models/outputConditionValue';
import { OutputProcess } from 'src/app/shared/models/views-models/outputProcess.model';
import { SetpointCalculation } from 'src/app/shared/models/views-models/setpointCalculation.model';
import { SpConditionValue } from 'src/app/shared/models/views-models/spConditionValue.model';
import { SpTagValue } from 'src/app/shared/models/views-models/spTagValue.model';
import { TagValue } from 'src/app/shared/models/views-models/tagValue.model';
import { CycleInfoService } from 'src/app/shared/service/views-services/cycle-info.service';
import { FuzzyCalculationService } from 'src/app/shared/service/views-services/fuzzyCalculation.service';
import { SetpointCalculationService } from 'src/app/shared/service/views-services/setpointCalculation.service';
import { SpConditionsValuesService } from 'src/app/shared/service/views-services/spConditionValue.service';
import { TagValueService } from 'src/app/shared/service/views-services/tagValue.service';
import { formatFromServer } from 'src/app/shared/utils/date.utils';
import {
    AxisConfig,
    CycleOutput,
    CycleOutputType,
    TagAxis,
    getCycleOutputTagId,
    getCycleOutputType,
    getOutputTag,
} from './setpoint-models/axis.config';

export type Params = {
    output: CycleOutput;
    start: string;
    end: string;
};

export class TagsValues extends Array<TagValue> {}

export type SerieValue = CycleInfo | SetpointCalculation | TagsValues | SpConditionValue;

@Injectable({
    providedIn: 'root',
})
export class SetpointValuesService {
    private _$subjectCycleInfo: BehaviorSubject<CycleInfo[]> = new BehaviorSubject([]);
    private _$subjectSpCalculation: BehaviorSubject<SetpointCalculation[]> = new BehaviorSubject([]);
    private _$subjectSpTagValues: BehaviorSubject<TagValue[]> = new BehaviorSubject([]);
    private _$subjectTagValues: BehaviorSubject<TagsValues[]> = new BehaviorSubject([]);
    private _$subjectPVValues: BehaviorSubject<TagValue[]> = new BehaviorSubject([]);
    private _$subjectMVValues: BehaviorSubject<TagValue[]> = new BehaviorSubject([]);
    private _$subjectSpConditionValues: BehaviorSubject<SpConditionValue[]> = new BehaviorSubject([]);
    private _$subjectValuesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private _$subjectProcessOutputValues: BehaviorSubject<OutputProcess> = new BehaviorSubject(null);
    private _$subjectProcessOutputConditionValues: BehaviorSubject<OutputConditionValue> = new BehaviorSubject(null);

    public $observeCycleInfo: Observable<CycleInfo[]> = this._$subjectCycleInfo.asObservable();
    public $observeSpCalculation: Observable<SetpointCalculation[]> = this._$subjectSpCalculation.asObservable();
    public $observeSubjectSpTagValues: Observable<TagValue[]> = this._$subjectSpTagValues.asObservable();
    public $observeTagValues: Observable<TagsValues[]> = this._$subjectTagValues.asObservable();
    public $observePVValues: Observable<TagValue[]> = this._$subjectPVValues.asObservable();
    public $observeMVValues: Observable<TagValue[]> = this._$subjectMVValues.asObservable();
    public $observeValuesLoaded: Observable<boolean> = this._$subjectValuesLoaded.asObservable();
    public $observeSpConditionValue: Observable<SpConditionValue[]> = this._$subjectSpConditionValues.asObservable();
    public $observeProcessOutputValues: Observable<OutputProcess> = this._$subjectProcessOutputValues.asObservable();
    public $observeProcessOutputConditionValues: Observable<OutputConditionValue> =
        this._$subjectProcessOutputConditionValues.asObservable();

    private _cycleOutputType: CycleOutputType;

    constructor(
        private cycleInfoService: CycleInfoService,
        private spCalculationService: SetpointCalculationService,
        private tagValueService: TagValueService,
        private spConditionsValuesService: SpConditionsValuesService,
        private fuzzyCalculationService: FuzzyCalculationService
    ) {}

    async loadDataFromAxisConfig(axisConfig: AxisConfig, start: string, end: string) {
        if (!this._$subjectValuesLoaded.value) {
            return;
        }
        this._$subjectValuesLoaded.next(false);
        const params: Params = {
            output: axisConfig.output,
            start: start,
            end: end,
        };
        if (getCycleOutputType(axisConfig.output) == 'Setpoint') {
            this._cycleOutputType = CycleOutputType.SETPOINT;
            await this._updateSubjectsForSetpoints(params, axisConfig.tags || []);
        }
        if (getCycleOutputType(axisConfig.output) == 'OutputProcess') {
            this._cycleOutputType = CycleOutputType.OUTPUT;
            await this._updateSubjectsForOutputProcess(params, axisConfig.tags || []);
        }
        this._$subjectValuesLoaded.next(true);
    }

    private async _updateSubjectsForSetpoints(params: Params, tags: TagAxis[]) {
        const [cycleInfo, spCalculation, spTagValues, tagValues, spConditions, pvValues, mvValues] = await Promise.all([
            this._getCycleInfo(params),
            this._getSetpointCalculation(params),
            this._getSpTagValues(params),
            this._getTagValues(params, tags),
            this._getSpConditionValues(params),
            this._getPVTagValues(params),
            this._getMVTagValues(params),
        ]);
        this._$subjectCycleInfo.next(cycleInfo);
        this._$subjectSpCalculation.next(spCalculation);
        this._$subjectSpTagValues.next(spTagValues);
        this._$subjectTagValues.next(tagValues);
        this._$subjectSpConditionValues.next(spConditions);
        this._$subjectPVValues.next(pvValues);
        this._$subjectMVValues.next(mvValues);
    }

    private async _updateSubjectsForOutputProcess(params: Params, tags: TagAxis[]) {
        const [cycleInfo, processOutputValues, processOutputConditionValues, tagValues] = await Promise.all([
            this._getCycleInfo(params),
            this._getProcessOutputValues(params),
            this._getProcessOutputConditionValues(params),
            this._getTagValues(params, tags),
        ]);
        this._$subjectCycleInfo.next(cycleInfo);
        this._$subjectProcessOutputValues.next(processOutputValues);
        this._$subjectProcessOutputConditionValues.next(processOutputConditionValues);
        this._$subjectTagValues.next(tagValues);
    }

    private async _getCycleInfo(params: Params): Promise<CycleInfo[]> {
        return await lastValueFrom(
            this.cycleInfoService.getAllWithoutDependencies(
                getCycleOutputTagId(params.output, true),
                params.start,
                params.end
            )
        );
    }

    private async _getSetpointCalculation(params: Params): Promise<SetpointCalculation[]> {
        return (
            await lastValueFrom(
                this.spCalculationService.getSetpointCalculations(params.output.id, params.start, params.end)
            )
        ).map((spCalculation) => {
            spCalculation.timestamp = formatFromServer(spCalculation.timestamp);
            return spCalculation;
        });
    }

    private async _getSpConditionValues(params: Params): Promise<SpConditionValue[]> {
        return (
            await lastValueFrom(
                this.spConditionsValuesService.getSpConditionsValues(params.output.id, params.start, params.end)
            )
        ).map((spCondition) => {
            spCondition.timestamp = formatFromServer(spCondition.timestamp);
            return spCondition;
        });
    }

    private async _getTagValues(params: Params, tags: TagAxis[]): Promise<TagsValues[]> {
        return Promise.all(
            tags
                .filter((t) => getOutputTag(params.output)?.id != getOutputTag(t.setpoint)?.id)
                .map(async (tag) =>
                    (await this._getValuesForTag(tag.id, params.start, params.end, tag.type, tag.setpoint)).map(
                        (tagValue: TagValue) => {
                            tagValue.tag = { ...tag };
                            return tagValue;
                        }
                    )
                )
        );
    }

    private async _getSpTagValues(params: Params): Promise<TagValue[]> {
        return this._getValuesForTag(getOutputTag(params.output).id, params.start, params.end);
    }

    private async _getPVTagValues(params: Params): Promise<TagValue[]> {
        const incrementSetpoint = params.output as IncrementSetpoint;
        if (incrementSetpoint.pv_tag != null)
            return this._getValuesForTag(incrementSetpoint.pv_tag.id, params.start, params.end);
    }

    private async _getMVTagValues(params: Params): Promise<TagValue[]> {
        const incrementSetpoint = params.output as IncrementSetpoint;
        if (incrementSetpoint.mv_tag != null)
            return this._getValuesForTag(incrementSetpoint.mv_tag.id, params.start, params.end);
    }

    private async _getProcessOutputValues(params: Params): Promise<OutputProcess> {
        let processOutput = params.output as OutputProcess;
        processOutput.tagValues = await this._getValuesForTag(
            getCycleOutputTagId(params.output, true),
            params.start,
            params.end
        );
        return processOutput;
    }

    private async _getProcessOutputConditionValues(params: Params): Promise<OutputConditionValue> {
        const outputTag = params.output as OutputProcess;
        if (!outputTag.conditionId) return null;
        let outputConditionTag = new OutputConditionValue();
        outputConditionTag.id = outputTag.conditionId;
        outputConditionTag.tagValues = await this._getValuesForTag(outputTag.conditionId, params.start, params.end);
        return outputConditionTag;
    }

    private async _getValuesForTag(
        id: string,
        startDate: string,
        endDate: string,
        type: VariableTypeEnum = VariableTypeEnum.ANALOGIC,
        setpoint: IncrementSetpoint = null
    ) {
        if (type === VariableTypeEnum.FUZZY) {
            return (await lastValueFrom(this.fuzzyCalculationService.getFuzzyCalcultions(id, startDate, endDate))).map(
                this._convertFuzzyCalculationToTagValue
            );
        }

        if (type == VariableTypeEnum.SETPOINT) {
            if (setpoint) {
                return (
                    await lastValueFrom(
                        this.tagValueService.getTagValues(getOutputTag(setpoint).id, startDate, endDate)
                    )
                ).map((tag) => {
                    tag.timestamp = formatFromServer(tag.timestamp);
                    return tag;
                });
            }
        }

        return (await lastValueFrom(this.tagValueService.getTagValues(id, startDate, endDate))).map((tag) => {
            tag.timestamp = formatFromServer(tag.timestamp);
            return tag;
        });
    }

    getLastSerieValue<SerieValue>(serieValue: new (...args) => SerieValue) {
        const values = this.getSeriesValues<SerieValue>(serieValue);
        return values[values.length - 1];
    }

    getSerieValueByTimestamp<SerieValue>(serieValue: new (...args) => SerieValue, timestamp: Date): SerieValue {
        let values = this.getSeriesValues<SerieValue>(serieValue);

        if (!values) return;

        if (serieValue == SetpointCalculation) {
            return (values as SetpointCalculation[]).find(
                (sp) => +new Date(sp.timestamp) === +new Date(timestamp)
            ) as SerieValue;
        }
        if (serieValue == CycleInfo) {
            return (values as CycleInfo[]).find(
                (cy) => +new Date(cy.cycleTimestamp) === +new Date(timestamp)
            ) as SerieValue;
        }

        if (serieValue == OutputProcess) {
            return (values as TagValue[]).find(
                (out) => +new Date(out.timestamp) === +new Date(timestamp)
            ) as SerieValue;
        }
    }

    getSeriesValues<SerieValue>(serieValue: new (...args) => SerieValue) {
        if (serieValue == CycleInfo) {
            return this._$subjectCycleInfo.value as Array<SerieValue>;
        }

        if (serieValue == SetpointCalculation) {
            return this._$subjectSpCalculation.value as Array<SerieValue>;
        }
        if (serieValue == SpTagValue) {
            return this._$subjectSpTagValues.value as Array<SerieValue>;
        }

        if (serieValue.name == 'TagsValues') {
            return this._$subjectTagValues.value as Array<SerieValue>;
        }

        if (serieValue == SpConditionValue) {
            return this._$subjectSpConditionValues.value as Array<SerieValue>;
        }

        if (serieValue == OutputProcess) {
            return this._$subjectProcessOutputValues?.value?.tagValues as Array<SerieValue>;
        }

        if (serieValue == OutputConditionValue) {
            return this._$subjectProcessOutputConditionValues?.value?.tagValues as Array<SerieValue>;
        }

        //Tags Values é default
        return this._$subjectTagValues.value as Array<SerieValue>;
    }

    getPVValues() {
        return this._$subjectPVValues.value;
    }

    getMVValues() {
        return this._$subjectMVValues.value;
    }

    async getDependencyTree(outputTagId: string, cycleId: string): Promise<CycleInfo> {
        return lastValueFrom(this.cycleInfoService.getDependencyTree(outputTagId, cycleId));
    }

    getCycleOutputType(): CycleOutputType {
        return this._cycleOutputType;
    }

    private _convertFuzzyCalculationToTagValue(fuzzyCalculation: FuzzyCalculation): TagValue {
        let tagValue = new TagValue();
        tagValue.id = fuzzyCalculation.id;
        tagValue.tag = fuzzyCalculation.fuzzy_tag;
        tagValue.value = fuzzyCalculation.fuzzy_value;
        tagValue.timestamp = formatFromServer(fuzzyCalculation.timestamp);
        return tagValue;
    }

    public getTagValuesBySeriesName(tagSeriesName) {
        return this._$subjectTagValues.value.find((t) => t && t.some((tag) => tag.tag.name == tagSeriesName));
    }

    public getTagValuesBySeriesNameAndDate(tagSeriesName, date) {
        const tagValues = this.getTagValuesBySeriesName(tagSeriesName);
        if (tagValues) {
            return tagValues.find((tag) => +new Date(tag.timestamp) === +new Date(date));
        }
        return null;
    }
}
