import { Injectable } from '@angular/core';

import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../components/dialogs/confirm-dialog/confirm-dialog.component';
import { CurrentProjectService } from '../views-services/current-project.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public dialog: MatDialog, private currentProjectService: CurrentProjectService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Adiciona a autorização (jwt Token)  no cabeçalho caso o usuário já tenha se autenticado
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (currentUser && currentUser.token) {
            const currentProject = this.currentProjectService.getCurrentProject();

            let project_id = currentProject?.id;
            let project_main_id = currentProject?.mainProjectId;
            let project_version_type = currentProject?.versionType;
            let project_name = currentProject?.name;
            let project_version = currentProject?.version;
            let header = {
                Authorization: `Bearer ${currentUser.token}`,
            };

            if (project_id) {
                header['project-id'] = project_id;
            }

            if (project_main_id) {
                header['project-main-id'] = project_main_id;
            }

            if (project_version_type) {
                header['project-version-type'] = project_version_type;
            }

            if (project_name) {
                header['project-name'] = project_name;
            }

            if (project_version) {
                header['project-version'] = `${project_version}`;
            }

            request = request.clone({
                setHeaders: header,
            });
        }

        if (this.isRequestAvailable(request, next)) {
            return next.handle(request).pipe(
                tap(
                    (ev: HttpEvent<any>) => {
                        if (ev instanceof HttpResponse) {
                            if (
                                request.url.endsWith('/signin') &&
                                request.method === 'POST' &&
                                ev.body &&
                                ev.body.data
                            ) {
                                localStorage.setItem('currentUser', JSON.stringify(ev.body.data));
                            } else if (request.url.indexOf('refresh-token') != -1 && ev.body.data) {
                                localStorage.setItem('currentUser', JSON.stringify(ev.body.data));
                            }
                        }
                    },
                    (error: any) => {
                        if (error instanceof HttpErrorResponse) {
                            return throwError(error?.error?.error);
                        }
                    }
                )
            );
        } else {
            return;
        }
    }

    private isRequestAvailable(request: HttpRequest<any>, next: HttpHandler): boolean {
        const project = this.currentProjectService.getCurrentProject();

        const isEditRequest =
            request.method == 'POST' ||
            request.method == 'PUT' ||
            request.method == 'PATCH' ||
            request.method == 'DELETE';

        const isProjectRequest =
            request.url.includes('project') ||
            request.url.includes('user') ||
            request.url.includes('opcServer') ||
            request.url.includes('all') ||
            request.url.includes('auth');

        if (project?.versionType == 'RUNNING' && isEditRequest && !isProjectRequest) {
            let conf = {
                component: ConfirmDialogComponent,
                panelClass: 'pop-up-dialog-container',
                minWidth: 'auto',
                data: {
                    title: 'Projeto está na versão RUNNING',
                    message: 'Altere o projeto para BUILDING para executar esta operação ',
                    cancelAvailable: false,
                    confirmButton: 'OK',
                },
            };
            this.dialog.open(conf.component, {
                panelClass: conf.panelClass,
                minWidth: conf.minWidth ? conf.minWidth : 'auto',
                height: 'auto',
                data: conf.data,
            });

            return false;
        }

        return true;
    }
}
