import { Component, Input, ViewChild } from '@angular/core';
import { OpenPanelInfo, PanelInfoFactory, SidePanelInfo } from 'src/app/shared/models/views-models/sidepanelInfo.model';
import { CycleOutputType } from '../setpoint-models/axis.config';
import { SetpointValuesService } from '../setpoint-values.service';
import { MatDrawer } from '@angular/material/sidenav';
import { verifyCanShowTextTooltip } from 'src/app/shared/utils/common.utils';
@Component({
    selector: 'app-side-panel-info',
    templateUrl: './side-panel-info.component.html',
    styleUrls: ['./side-panel-info.component.scss'],
})
export class SidePanelInfoComponent {
    @ViewChild('drawer') sidepanelDrawer: MatDrawer;

    public sidepanelInfo: SidePanelInfo;
    public _cycleOutputType: CycleOutputType;
    public _panelConfig: OpenPanelInfo;
    public _currentSelecteSetpointdName: string = '';

    @Input() set panelConfig(value: OpenPanelInfo) {
        this._panelConfig = value;
        this.LoadInfo();
    }

    constructor(private setpointvalueService: SetpointValuesService) {}

    ngOnInit(): void {}

    async LoadInfo() {
        this.LoadPanelInfo();
    }

    LoadPanelInfo() {
        this._cycleOutputType = this.setpointvalueService.getCycleOutputType();
        let panelInfo = PanelInfoFactory.createPanelInfo(
            this._cycleOutputType,
            this.setpointvalueService,
            this._panelConfig
        );

        this.sidepanelInfo = panelInfo.getSidePanelInfo();
        this._panelConfig.name = this._panelConfig?.output?.name;
        if (this._panelConfig) this._panelConfig.drawer = panelInfo.getSidePanelDrawerInfo();
        this._handleSidePanelDrawer();
    }

    private _handleSidePanelDrawer() {
        if (this._hasSetpointChanged()) this.sidepanelDrawer?.close();
        if (!this._panelConfig?.autoOpen) return;
        this.sidepanelDrawer?.open();
    }

    private _hasSetpointChanged(): boolean {
        if (this._currentSelecteSetpointdName === this._panelConfig?.name) return false;
        this._currentSelecteSetpointdName = this._panelConfig?.name;
        return true;
    }

    get isSetpoint() {
        return this._cycleOutputType === CycleOutputType.SETPOINT;
    }

    get panelTitle(): string {
        return this.isSetpoint ? 'SETPOINT' : 'SAÍDA';
    }

    getIsConditionValid(value: any): string {
        if (this.isNullOrUndefined(value)) return;
        return value ? 'Sim' : 'Não';
    }

    getIsIncDecValid(value: any): string {
        if (this.isNullOrUndefined(value)) return '-';
        return value ? 'Sim' : 'Não';
    }

    getConditionSymbol(value: any): string {
        if (this.isNullOrUndefined(value)) return;
        return value ? 'check_circle' : 'cancel';
    }

    isNullOrUndefined(value: string): boolean {
        return value === null || value === undefined;
    }

    public getTooltipText(text: string): string {
        return verifyCanShowTextTooltip(this.sidepanelInfo.tagName, 26);
    }
}
