import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Response } from '../../models/response.model';
import { CycleInfo } from '../../models/views-models/cycleInfo';
import { CurrentProjectService } from './current-project.service';
import { formatDate, formatDateObj, formatToServer } from '../../utils/date.utils';

const API = environment.serverUrl;
const endpoint = `${API}cycle-info`;

@Injectable()
export class CycleInfoService {
    endpoint: string;
    constructor(private httpClient: HttpClient, private currentProjectService: CurrentProjectService) {}

    getAll(startDate: string, endDate: string): Observable<CycleInfo[]> {
        let httpParams = new HttpParams().appendAll({
            startDate: formatToServer(startDate),
            endDate: formatToServer(endDate),
        });
        return this.httpClient.get<{ data: CycleInfo[] }>(`${endpoint}/all`, { params: httpParams }).pipe(
            map((response) => {
                const cyclesInfo: CycleInfo[] = response.data;
                return cyclesInfo;
            })
        );
    }

    getAllWithoutDependencies(outputTagId: string, startDate: string, endDate: string): Observable<CycleInfo[]> {
        const body = {
            outputTagId: outputTagId,
            startDate: formatToServer(startDate),
            endDate: formatToServer(endDate),
        };
        return this.httpClient.post<{ data: CycleInfo[] }>(`${endpoint}/all`, body).pipe(
            map((response) => {
                const cyclesInfo: CycleInfo[] = response.data;
                return cyclesInfo;
            })
        );
    }

    getDependencyTree(outputTagId: string, cycleId: string): Observable<CycleInfo> {
        let httpParams = new HttpParams().appendAll({
            outputId: outputTagId,
        });
        return this.httpClient
            .get<{ data: CycleInfo }>(`${endpoint}/dependencies/${cycleId}`, { params: httpParams })
            .pipe(
                map((response) => {
                    const cyclesInfo: CycleInfo = response.data;
                    return cyclesInfo;
                })
            );
    }
}
