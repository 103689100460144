<form [formGroup]="projectSettingsForm" (ngSubmit)="onSubmit()">
    <ihm-card-header data-cy="title" icon="settings" [title]="'Configurações do Projeto'"> </ihm-card-header>
    <div class="container body" fxLayout="column">
        <div class="sub-title">
            Nebuluz Core
            <div fxFlex fxLayoutAlign="end center">
                <span class="project-status">Sistema {{ on_off }}</span>
                <mat-slide-toggle
                    formControlName="project_on"
                    color="primary"
                    (change)="projectStateChange()"
                    data-cy="project-on"
                ></mat-slide-toggle>
            </div>
        </div>
        <div class="sub-title">Sistema Ligado/Desligado</div>
        <datalist id="opcServersList">
            <option *ngFor="let opcServer of opcServers" [value]="opcServer.name"></option>
        </datalist>
        <div fxLayout="row" fxLayoutAlign="center center" class="material-input">
            <mat-form-field appearance="outline" fxFlex="40%" class="opc-server-container">
                <mat-label>Servidor</mat-label>
                <input
                    matInput
                    list="opcServersList"
                    type="text"
                    formControlName="project_on_opc_server"
                    data-cy="opc-server-on"
                    (focus)="resetInputOpcServer('project_on_opc_server')"
                    (focusout)="setOpcServer($event.target.value, 'project_on_opc_server')"
                />
                <mat-error *ngIf="isFieldInvalid('project_on_opc_server')"> *O campo servidor é obrigatório </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="opc-address-container">
                <mat-label>Endereço OPC para Sistema Ligado/Desligado</mat-label>
                <input matInput type="text" formControlName="project_on_opc" data-cy="project-on-opc" />
                <mat-error *ngIf="isFieldInvalid('project_on_opc')">
                    *O campo endereço OPC para sistema ligado/desligado é obrigatório
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" fxFlex="40%" class="opc-server-container">
                <mat-label>Servidor</mat-label>
                <input
                    matInput
                    list="opcServersList"
                    type="text"
                    formControlName="process_status_opc_server"
                    data-cy="opc-server-status"
                    (focus)="resetInputOpcServer('process_status_opc_server')"
                    (focusout)="setOpcServer($event.target.value, 'process_status_opc_server')"
                />
                <mat-error *ngIf="isFieldInvalid('process_status_opc_server')">
                    *O campo servidor é obrigatório
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="opc-address-container">
                <mat-label>Endereço OPC para Status do Processo</mat-label>
                <input matInput type="text" formControlName="process_status_opc" data-cy="project-status-opc" />
                <mat-error *ngIf="isFieldInvalid('process_status_opc')">
                    *O campo endereço OPC para status do processo é obrigatório
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" fxFlex="40%" class="opc-server-container">
                <mat-label>Servidor</mat-label>
                <input
                    matInput
                    list="opcServersList"
                    type="text"
                    formControlName="project_apt_opc_server"
                    data-cy="opc-server-apt-opc"
                    (focus)="resetInputOpcServer('project_apt_opc_server')"
                    (focusout)="setOpcServer($event.target.value, 'project_apt_opc_server')"
                />
                <mat-error *ngIf="isFieldInvalid('project_apt_opc_server')">
                    *O campo servidor é obrigatório
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="opc-address-container">
                <mat-label>Endereço OPC para Sistema Apto</mat-label>
                <input matInput type="text" formControlName="project_apt_opc" data-cy="project-apt-opc" />
                <mat-error *ngIf="isFieldInvalid('project_apt_opc')">
                    *O campo endereço OPC para sistema apto é obrigatório
                </mat-error>
            </mat-form-field>
        </div>

        <mat-divider></mat-divider>

        <div fxFlex fxLayoutAlign="center center" class="mv-container">
            <span>Controle por Variável Manipulada (MV)</span>
            <mat-slide-toggle
                formControlName="mv_control"
                color="primary"
                (click)="checkIfDisableIsAllowed()"
                data-cy="mv-control"
            ></mat-slide-toggle>
        </div>
        <mat-divider></mat-divider>

        <div fxLayoutAlign="center center" class="row-height">
            <mat-form-field appearance="outline" fxFlex="40%" class="opc-server-container">
                <mat-label>Tempo do Ciclo</mat-label>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <input matInput type="number" min="1" formControlName="cycle_time" data-cy="cycle-time" />
                    <span matTextSuffix>Seg.</span>
                </div>
                <mat-error *ngIf="isFieldInvalid('cycle_time')"> *O tempo do ciclo é obrigatório </mat-error>
            </mat-form-field>
        </div>

        <mat-divider></mat-divider>

        <span class="sub-title">Watchdog</span>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" fxFlex="40%" class="opc-server-container">
                <mat-label>Servidor</mat-label>
                <input
                    matInput
                    list="opcServersList"
                    type="text"
                    formControlName="watchdog_opc_server"
                    data-cy="opc-server-watchdog"
                    (focus)="resetInputOpcServer('watchdog_opc_server')"
                    (focusout)="setOpcServer($event.target.value, 'watchdog_opc_server')"
                />
                <mat-error *ngIf="isFieldInvalid('watchdog_opc_server')"> *O campo servidor é obrigatório </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="opc-address-container">
                <mat-label>Endereço OPC de Escrita</mat-label>
                <input matInput type="text" formControlName="watchdog_opc" data-cy="watchdog-opc" />
                <mat-error *ngIf="isFieldInvalid('watchdog_opc')"> *O endereço OPC de escrita é obrigatório </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
            <div class="one-row">
                <mat-form-field appearance="outline">
                    <mat-label>Timeout de comunicação</mat-label>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <input matInput type="number" min="1" formControlName="watchdog" data-cy="watchdog" />
                        <span matTextSuffix>Seg.</span>
                    </div>
                    <mat-error *ngIf="isFieldInvalid('watchdog')"> *O timeout de comunicação é obrigatório </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px" class="buttons">
        <button mat-button class="cancelButton" type="button" (click)="close(false)">CANCELAR</button>
        <button mat-button class="blueButton" type="submit" *ngIf="user.permissions.canUpdate && !isShowForm()" data-cy="save">
            SALVAR
        </button>
    </div>
</form>
<app-loader *ngIf="isLoading" [loaderText]="loaderMessage"></app-loader>
