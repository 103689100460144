import { filter } from 'rxjs';
import { CycleOutputType } from 'src/app/views/setpoints-tracking/setpoint-models/axis.config';
import { normalizeValue } from 'src/app/views/setpoints-tracking/setpoint-models/setpoint-chart.config';
import { SetpointValuesService } from 'src/app/views/setpoints-tracking/setpoint-values.service';
import { CycleStatusEnum } from '../enum/cycleStatus.enum';
import { CycleInfo } from './cycleInfo';
import { OutputConditionValue } from './outputConditionValue';
import { OutputProcess } from './outputProcess.model';
import { SetpointCalculation } from './setpointCalculation.model';
import { TagValue } from './tagValue.model';

export class SidePanelInfo {
    increment: number | string;
    setpointValue: number | string;
    conditionsValid: boolean;
    setpointOn: boolean;
    tagName: string;
    incrementValue: number;
}

export class OpenPanelInfo {
    timestamp: Date;
    autoOpen: boolean;
    cycleInfo: CycleInfo;
    name: string;
    drawer: any;
    output: any;
}

export class PanelInfoFactory {
    static createPanelInfo(type: string, setpointValueService: SetpointValuesService, panelConfig: OpenPanelInfo) {
        if (type == CycleOutputType.SETPOINT) return new SetpointPanelInfo(setpointValueService, panelConfig);
        return new OutputProcessPanelInfo(setpointValueService, panelConfig);
    }
}

export class PanelInfo {
    sidePanelInfo: SidePanelInfo = new SidePanelInfo();
    sidePanelDrawerInfo: any = new Object();

    constructor(public setpointvalueService: SetpointValuesService, public panelConfig: OpenPanelInfo) {}

    getSidePanelInfo(): SidePanelInfo {
        throw new Error('Responsibility of the subclass.');
    }

    getSidePanelDrawerInfo() {}
}

export class SetpointPanelInfo extends PanelInfo {
    constructor(setpointvalueService: SetpointValuesService, panelConfig: OpenPanelInfo) {
        super(setpointvalueService, panelConfig);
        this._loadData();
    }

    getSidePanelInfo(): SidePanelInfo {
        return this.sidePanelInfo;
    }

    getSidePanelDrawerInfo() {
        return this.sidePanelDrawerInfo;
    }

    private _loadData() {
        this.setpointvalueService.$observeSpCalculation.pipe(filter((value) => Boolean(value))).subscribe((values) => {
            let spvalue = values.find((sp) => +new Date(sp.timestamp) === +new Date(this.panelConfig?.timestamp));
            this._setSidePanelInfo(spvalue);
            this._setSidePanelDrawerInfo(spvalue);
        });
    }

    private _setSidePanelInfo(setpoint: SetpointCalculation) {
        this.sidePanelInfo.increment = normalizeValue(setpoint?.increment);
        this.sidePanelInfo.incrementValue = isNaN(Number(normalizeValue(setpoint?.increment)))
            ? 0
            : Number(normalizeValue(setpoint?.increment));
        this.sidePanelInfo.setpointValue = normalizeValue(setpoint?.newValue);
        this.sidePanelInfo.setpointOn = this._setSetpointStatus();
        this.sidePanelInfo.conditionsValid = setpoint?.conditionsValid;
        this.sidePanelInfo.tagName = this.panelConfig?.name ?? setpoint?.incrementSetpoint?.new_sp_tag?.name;
    }

    private _setSidePanelDrawerInfo(setpoint: SetpointCalculation) {
        this.sidePanelDrawerInfo.cycles = setpoint?.cycles;
        this.sidePanelDrawerInfo.currentCycle = setpoint?.currentCycle;
        this.sidePanelDrawerInfo.increment = setpoint?.increment;
        this.sidePanelDrawerInfo.setpointOn = this._setSetpointStatus();
        this.sidePanelDrawerInfo.writeCycle = setpoint?.writeCycle;
        this.sidePanelDrawerInfo.increment = setpoint?.increment;
        this.sidePanelDrawerInfo.conditionsValid = setpoint?.conditionsValid;
        this.sidePanelDrawerInfo.equation = setpoint?.equation;
        this.sidePanelDrawerInfo.conditionsActive = setpoint?.conditionsActive;
        this.sidePanelDrawerInfo.conditionValues = setpoint?.conditionValues;
        this.sidePanelDrawerInfo.equation = setpoint?.equation;
        this.sidePanelDrawerInfo.currentValue = normalizeValue(setpoint?.currentValue);
        this.sidePanelDrawerInfo.fuzzyValue = normalizeValue(setpoint?.fuzzyValue);
        this.sidePanelDrawerInfo.factor = normalizeValue(setpoint?.factor);
        this.sidePanelDrawerInfo.increment = normalizeValue(setpoint?.increment);
        this.sidePanelDrawerInfo.newValue = normalizeValue(setpoint?.newValue);
        this.sidePanelDrawerInfo.minLimit = setpoint?.minLimit;
        this.sidePanelDrawerInfo.maxLimit = setpoint?.maxLimit;
    }

    private _setSetpointStatus() {
        if (this.panelConfig?.cycleInfo?.dependency?.status == CycleStatusEnum.disabled) return false;
        return true;
    }
}

export class OutputProcessPanelInfo extends PanelInfo {
    constructor(setpointvalueService: SetpointValuesService, panelConfig: OpenPanelInfo) {
        super(setpointvalueService, panelConfig);

        this._loadData();
    }

    getSidePanelInfo(): SidePanelInfo {
        return this.sidePanelInfo;
    }

    getSidePanelDrawerInfo() {
        return this.sidePanelDrawerInfo;
    }

    private _loadData() {
        this.setpointvalueService.$observeProcessOutputValues
            .pipe(filter((value) => Boolean(value)))
            .subscribe((values) => {
                let diff = Infinity;
                let outputValue;
                values.tagValues.forEach((sp) => {
                    const spTimestamp = +new Date(sp.timestamp);
                    const panelTimestamp = +new Date(this.panelConfig?.timestamp);
                    const currentDiff = Math.abs(spTimestamp - panelTimestamp);
                    if (currentDiff < diff) {
                        outputValue = sp;
                        diff = currentDiff;
                    }
                });
                this._setSidePanelInfo(values, outputValue);
                this._setSidePanelDrawerInfo(values, outputValue);
            });
    }

    private _setSidePanelInfo(processOutput: any, processOutputValue: any) {
        this.sidePanelInfo.setpointValue = normalizeValue(processOutputValue?.value);
        this.sidePanelInfo.setpointOn = this._setOutputStatus();
        this.sidePanelInfo.tagName = this.panelConfig?.name ?? processOutput?.name;
        this.sidePanelInfo.conditionsValid = this._isConditionValid(processOutput);
    }

    private _setSidePanelDrawerInfo(processOutput: OutputProcess, processOutputValue: TagValue) {
        this.sidePanelDrawerInfo.currentValue = normalizeValue(processOutputValue?.value);
        this.sidePanelDrawerInfo.setpointOn = this._setOutputStatus();
        this.sidePanelDrawerInfo.conditionsValid = this._isConditionValid(processOutput);
    }

    private _setOutputStatus() {
        if (this.panelConfig?.cycleInfo?.dependency?.status == CycleStatusEnum.disabled) return false;
        return true;
    }

    private _isConditionValid(processOutput: OutputProcess) {
        if (!processOutput?.conditionId) return true;
        return this._isValueTrue(this._getConditionValue());
    }

    private _getConditionValue(): any {
        const conditionValues = this.setpointvalueService.getSeriesValues(
            OutputConditionValue
        ) as unknown as TagValue[];
        const conditionValue = conditionValues?.find(
            (value) => +new Date(value.timestamp) === +new Date(this.panelConfig?.timestamp)
        );
        return conditionValue?.value;
    }

    private _isValueTrue(value: any): boolean {
        const trueValues = [1, '1', 'true', 'True'];
        return trueValues.includes(value);
    }
}
