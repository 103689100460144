import { Component, EventEmitter, Input, IterableDiffers, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { Process } from 'src/app/shared/models/views-models/process.model';
import { ProcessType } from 'src/app/shared/models/views-models/processType.model';
import { ProcessInputService } from 'src/app/shared/service/views-services/process.service';
import { ProcessTypeService } from 'src/app/shared/service/views-services/processType.service';
import { SystemParService } from 'src/app/shared/service/views-services/systemPar.service';
import { getUserData } from 'src/app/shared/utils/userRole';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { ProcessInputComponent } from '../process-input/process-input.component';
import { Router } from '@angular/router';
import { Views } from 'src/app/shared/utils/views-urls';
import { getProject } from 'src/app/shared/utils/projectUtils';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { PopUpMessagesComponent } from '../../pop-up-messages/pop-up-messages.component';

@Component({
    selector: 'app-table-inputs',
    templateUrl: './table-inputs.component.html',
    styleUrls: ['./table-inputs.component.scss'],
})
export class TableInputsComponent implements OnInit {
    @Input() table?: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @Output() updateData = new EventEmitter();

    user: any;
    qbPort: any;
    qbHost: any;

    dataSource: MatTableDataSource<Object>;
    iterableDiffer: any;
    lastUpdate: string;
    message = '';
    displayedColumns = [
        'select',
        'name',
        'description',
        'type',
        'min',
        'max',
        'value',
        'quality',
        'timestamp',
        'actions',
    ];
    shortColumns = ['name', 'description', 'type', 'min', 'max', 'value', 'quality', 'timestamp', 'actions'];
    mobileColumns = ['name', 'description', 'type', 'value', 'quality', 'timestamp', 'actions'];
    dialogRef: any;
    processList: Process[];
    filteredInputs: Process[];
    processTypes: ProcessType[];
    searchText: string;
    showAllTypes = true;
    activeTypes = [];
    selectedList = [];
    deleteMessage = MessagesEnum.DeleteMessage;
    inputDeleteSuccessMessage = MessagesEnum.inputDeleteSuccessMessage;
    inputDeleteErrorMessage = MessagesEnum.inputDeleteErrorMessage;
    successIcon: string = 'assets/images/status/successMessage.svg';
    errorIcon: string = 'assets/images/status/errorMessage.svg';
    isLoading: boolean = false;
    loaderMessage: string = '';

    confirmDelete = {
        component: DeleteDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            singleDelete: true,
        },
    };

    // defaultDialog = {
    //     component: ConfirmDialogComponent,
    //     width: 'auto',
    //     height: 'auto',
    //     panelClass: 'pop-up-dialog-container',
    //     data: {
    //         message: '',
    //     },
    // };

    constructor(
        private _iterableDiffers: IterableDiffers,
        public snackBar: MatSnackBar,
        private processTypeService: ProcessTypeService,
        private systemParService: SystemParService,
        private processService: ProcessInputService,
        public currentProjectService: CurrentProjectService,
        public dialog: MatDialog,
        private router: Router
    ) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    ngOnInit() {
        this.loadData();
        this.user = getUserData();
        this.systemParService.getQuarterbackSettings().subscribe((settings) => {
            this.qbPort = settings.port;
            this.qbHost = settings.host;
        });
        if (!this.user.permissions.canCreate) {
            this.displayedColumns = this.shortColumns;
        }
    }

    markElement(process) {
        let index = this.selectedList.indexOf(process.id);
        if (index == -1) {
            this.selectedList.push(process.id);
        } else {
            this.selectedList.splice(index, 1);
        }
    }

    checkStatus(process) {
        let index = this.selectedList.indexOf(process.id);
        return index == -1 ? false : true;
    }

    deleteSelectedElements() {
        let conflictMessage = 'Conflito ao deletar.';
        var hasConflict = false;
        this.confirmDelete.data.singleDelete = false;

        this.openAlertDialog({
            component: PopUpMessagesComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: '',
                title: 'Excluir Entrada',
                subTitle: 'Você tem certeza que deseja excluir a(s) entradas(s) selecionada(s)? ',
                optionalText: 'Esta ação não pode ser desfeita.',
                firstButtonText: 'EXCLUIR',
                secondButtonText: 'CANCELAR',
                typePopUp: 'deletePopUp',
            },
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.selectedList.forEach((process, index) => {
                    this.processService.deleteProcess(process).subscribe({
                        next: (dependencies) => {
                            if (dependencies.length > 0) {
                                hasConflict = true;
                                let processTag = this.processList.find((tag) => tag.id == process);
                                var dependencieNames = '';
                                dependencies.forEach((tag) => {
                                    if (
                                        dependencieNames.indexOf(
                                            tag.name + '(' + tag.variableType.description + ')' + ', '
                                        ) == -1
                                    ) {
                                        dependencieNames += tag.name + '(' + tag.variableType.description + ')' + ', ';
                                    }
                                });
                                dependencieNames = dependencieNames.slice(0, -2) + '.';
                                conflictMessage +=
                                    ' A entrada ' +
                                    processTag.name +
                                    ' é usada como entrada em: ' +
                                    dependencieNames +
                                    '\n';
                            }

                            if (this.selectedList.length == index + 1) {
                                if (!hasConflict) {
                                    //TODO: Implement here flag to reload quarterback.
                                    this.openAlertDialog({
                                        component: PopUpMessagesComponent,
                                        width: 'auto',
                                        height: 'auto',
                                        panelClass: 'pop-up-dialog-container',
                                        data: {
                                            imageUrl: this.successIcon,
                                            title: this.inputDeleteSuccessMessage,
                                            typePopUp: 'titlePopUpInformationMessage',
                                        },
                                    });
                                } else {
                                    this.openAlertDialog({
                                        component: PopUpMessagesComponent,
                                        width: 'auto',
                                        height: 'auto',
                                        panelClass: 'pop-up-dialog-container',
                                        data: {
                                            imageUrl: this.errorIcon,
                                            title: conflictMessage,
                                            typePopUp: 'titlePopUpInformationMessage',
                                        },
                                    });
                                }
                                this.dialogRef.afterClosed().subscribe((result) => {
                                    this.selectedList = [];
                                    this.loadData();
                                });
                            }
                        },
                        error: (error) => {
                            console.error(error);
                            this.openAlertDialog({
                                component: PopUpMessagesComponent,
                                width: 'auto',
                                height: 'auto',
                                panelClass: 'pop-up-dialog-container',
                                data: {
                                    imageUrl: this.errorIcon,
                                    title: this.inputDeleteErrorMessage,
                                    subTitle: 'Não foi possível excluir as entrada(s).',
                                    optionalText: 'Tente novamente mais tarde.',
                                    typePopUp: 'titlePopUpInformationMessage',
                                },
                            });
                        },
                    });
                });
            }
        });
    }

    loadData() {
        this.processTypeService.getAllProcessTypes().subscribe((processTypes) => {
            this.processTypes = processTypes
                .filter(
                    (process) =>
                        ![
                            VariableTypeEnum.VIRTUAL_SETPOINT.valueOf(),
                            VariableTypeEnum.SYSTEM.valueOf(),
                            VariableTypeEnum.SETPOINT_STATUS.valueOf(),
                            VariableTypeEnum.OUTPUT.valueOf(),
                        ].includes(process.name)
                )
                .sort((a, b) => a.description.localeCompare(b.description));
            this.processTypes.forEach((type) => {
                this.activeTypes.push({ value: type, active: false });
            });
        });
        this.loadDatasource();
    }

    loadDatasource() {
        this.processService.getAllProcesses().subscribe((process) => {
            this.processList = process.filter(
                (process) =>
                    ![
                        VariableTypeEnum.VIRTUAL_SETPOINT.valueOf(),
                        VariableTypeEnum.SYSTEM.valueOf(),
                        VariableTypeEnum.SETPOINT_STATUS.valueOf(),
                        VariableTypeEnum.OUTPUT.valueOf(),
                    ].includes(process.process_type.name)
            );
            this.filteredInputs = this.processList;
            this.dataSource = new MatTableDataSource(this.processList);
            this.checkActiveTypes();
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = (data: { name: string }, filterValue: string) =>
                data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
                if (typeof data[sortHeaderId] === 'string') {
                    return data[sortHeaderId].toLocaleLowerCase();
                }
                return data[sortHeaderId];
            };
            if (this.searchText) {
                this.applyFilter(this.searchText);
            }
        });
        this.lastUpdate = new Date().toLocaleString();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.searchText = filterValue;
    }

    add() {
        this.openDialog(null);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.loadDatasource();
                this.checkActiveTypes();
            }
        });
    }

    openAlertDialog(options: any) {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            maxWidth: '720px',
            height: options.height,
            data: options.data,
        });
    }

    deleteElement(element: any) {
        this.openAlertDialog({
            component: PopUpMessagesComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                imageUrl: '',
                title: 'Excluir Entrada',
                subTitle: 'Você tem certeza que deseja excluir entrada? ',
                optionalText: 'Esta ação não pode ser desfeita.',
                firstButtonText: 'EXCLUIR',
                secondButtonText: 'CANCELAR',
                typePopUp: 'deletePopUp',
            },
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.startLoader('Excluindo entrada, aguarde...');
                this.processService.deleteProcess(element.id).subscribe({
                    next: (dependencies) => {
                        if (dependencies.length > 0) {
                            var dependencieNames = '';
                            dependencies.forEach((tag) => {
                                if (
                                    dependencieNames.indexOf(
                                        tag.name + '(' + tag.variableType.description + ')' + ', '
                                    ) == -1
                                ) {
                                    dependencieNames += tag.name + '(' + tag.variableType.description + ')' + ', ';
                                }
                            });
                            dependencieNames = dependencieNames.slice(0, -2) + '.';
                            this.openAlertDialog({
                                component: PopUpMessagesComponent,
                                width: 'auto',
                                height: 'auto',
                                panelClass: 'pop-up-dialog-container',
                                data: {
                                    imageUrl: this.errorIcon,
                                    title: 'Conflito ao deletar. Esta variável é usada em: ' + dependencieNames,
                                    typePopUp: 'titlePopUpInformationMessage',
                                },
                            });
                        } else {
                            this.selectedList = [];
                            this.openAlertDialog({
                                component: PopUpMessagesComponent,
                                width: 'auto',
                                height: 'auto',
                                panelClass: 'pop-up-dialog-container',
                                data: {
                                    imageUrl: this.successIcon,
                                    title: this.inputDeleteSuccessMessage,
                                    typePopUp: 'titlePopUpInformationMessage',
                                },
                            });
                            let index = this.processList.indexOf(element);
                            this.processList.splice(index, 1);
                            this.checkActiveTypes();
                        }
                        this.stopLoader();
                    },
                    error: (error) => {
                        console.error(error);
                        this.openAlertDialog({
                            component: PopUpMessagesComponent,
                            width: 'auto',
                            height: 'auto',
                            panelClass: 'pop-up-dialog-container',
                            data: {
                                imageUrl: this.errorIcon,
                                title: this.inputDeleteErrorMessage,
                                subTitle: 'Não foi possível excluir entrada.',
                                optionalText: 'Tente novamente mais tarde.',
                                typePopUp: 'titlePopUpInformationMessage',
                            },
                        });
                        this.stopLoader();
                    },
                });
            }
        });
    }

    selectElement(element: any) {
        let processInfo = this.processList.find((item) => item.id == element.id);
        let processIndex = this.processList.indexOf(processInfo);
        let process = Object.assign({}, this.processList[processIndex]);
        this.openDialog(process);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.processList[processIndex] = result;
                this.checkActiveTypes();
            }
        });
    }

    openDialog(data: any) {
        this.dialogRef = this.dialog.open(ProcessInputComponent, {
            panelClass: 'custom-dialog-container',
            width: 'auto',
            height: 'auto',
            data: data,
        });
        this.dialogRef.afterClosed().subscribe((input) => {
            if (input) {
                if (!input.id) {
                    let index = this.processList.map((process) => process.tag.name).indexOf(input.name);
                    this.processList.splice(index, 1);
                    this.checkActiveTypes();
                }
            }
        });
    }

    changeAllTypes() {
        this.activeTypes.forEach((type) => {
            type.active = false;
        });
        this.showAllTypes = true;
        this.filteredInputs = this.processList;
        this.dataSource.data = this.filteredInputs.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
    }

    enableType(type) {
        let index = this.activeTypes.map((type) => type.value).indexOf(type);
        if (index != -1 && this.activeTypes[index].active) {
            this.showAllTypes = false;
            this.activeTypes[index] = { value: type, active: false };
        } else {
            this.activeTypes[index] = { value: type, active: true };
        }
        this.checkActiveTypes();
    }

    checkActiveTypes() {
        this.filteredInputs = this.processList;
        let isFilterActive = this.activeTypes.find((type) => type.active == true);
        if (isFilterActive) {
            this.activeTypes.forEach((type) => {
                if (!type.active) {
                    this.filteredInputs = this.filteredInputs.filter(
                        (input) => input.process_type.name != type.value.name
                    );
                }
            });
            this.showAllTypes = false;
        } else {
            this.showAllTypes = true;
        }
        this.dataSource.data = this.filteredInputs.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
    }

    trackInput(element) {
        this.router.navigate([Views.dataTreatment.url], { fragment: element.id });
    }

    startLoader(message: string) {
        this.isLoading = true;
        this.loaderMessage = message;
    }

    stopLoader() {
        this.isLoading = false;
        this.loaderMessage = '';
    }
}
